/**
    Create a React component representing each team member card
    on the About Us Page. This card contains the name, image, and
    description of the member.

    @author Cassandra Tin Kwon Yuen, Sergio Perez
*/

import TeamMemberPicture from "./TeamMemberPicture"
import defaultPicture from "../../../images/blank.png"
import { FaTwitter, FaLinkedin, FaFacebook, FaEnvelope } from 'react-icons/fa';

export default function TeamMemberCard({ name, picture, description }) {

    return (
        <div className={`team-member-card-container`}>
            <TeamMemberPicture picture={picture ? picture : defaultPicture} name={name} />
            <div className="member-name">{name}
                <div className="member-description">{description ? description : ""}</div>
            </div>
            <div className="member-socials">
                {/* {<a href='#' target="_blank" rel="noopener noreferrer" class="twitter-icon"><FaTwitter /></a>} */}
                {<a href='#' target="_blank" rel="noopener noreferrer" class="facebook-icon"><FaFacebook /></a>}
                {<a href='#' target="_blank" rel="noopener noreferrer" class="linkedin-icon"><FaLinkedin /></a>}
                {<a href='#' target="_blank" rel="noopener noreferrer" class="email-icon"><FaEnvelope /></a>}
            </div>
        </div>
    )
}