/**
   Create a react component representing the home landing
   page of the website. 

    @author Cassandra Tin Kwon Yuen
*/

import React from 'react';
import { Link } from "react-router-dom";
import HomeGridRow from './HomeGridRow';
import BannerImage from "../../images/banner-empty.png";
import SRImage from "../../images/systematic_review.png";
import DocumentImage from "../../images/doc.png";
import CautionImage from "../../images/caution.png";
import GoalImage from "../../images/goal.png";
import AIVector from "../../images/ai_illustration.png";

import "./Home.css";


const Home = () => {
    // Change the header colour to be the same as the background colour.
    var headerColour = getComputedStyle(document.body).getPropertyValue('--light-grey'); 
    document.body.style.backgroundColor = headerColour;

    return (
        <>
            {/* Top banner */}
            <div className="large-container">
                <img id="banner" src={BannerImage} alt="BANNER"></img>
                <div id="banner-overlay">
                    <h1 className="title">WISEST</h1>
                    <h2 className="subtitle">
                        <strong>W</strong>h<strong>I</strong>ch <strong>S</strong>ystematic  
                        <strong> E</strong>vidence <strong>S</strong>ynthesis is bes<strong>T</strong></h2>
                    <div className="button-container" id="try-button-small">
                        <Link to="/WisestTool">
                            <button className="button" type="button">
                                Try the WISEST Tool
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="background-block"/>
            </div>

            {/* Try the WISEST tool button */}
            <div className="button-container" id="try-button">
                <Link to="/WisestTool">
                    <button className="button" type="button">
                        Try the WISEST Tool
                    </button>
                </Link>
            </div>

            {/* SR definiion box */}
            <div className="large-container">
                <div className="definition-box">
                    <div className="box">
                        <h2 className="subtitle" id="sr-title">Systematic Review (SR):</h2>
                    </div>
                    <h3 className="body-text" id="sr-definition">
                        A type of evidence summary that combines the results of clinical studies on the same health condition
                    </h3>
                    <div id="sr-vector">
                        <img id="sr-image" src={SRImage} alt="SR"/>
                    </div>
                </div>
            </div>
            <div className="large-container" id="why-wisest">
                <h2 className="subtitle wisest-subtitle">WHY WISEST?</h2>
            </div>

            {/* Why WISEST? */}
            <div className="home-grid">
                <HomeGridRow icon={DocumentImage} iconName="DOCUMENT" subtitle="THE ISSUE"
                    text="Systematic reviews suffer from potential biases in their conduct, analysis, and interpretation. 
                    Quality assessment is integral to the practice of Evidence-Based Medicine."/>
                <HomeGridRow icon={CautionImage} iconName="CAUTION" subtitle="THE IMPACT"
                    text="If the results or conclusions of a systematic review are integrated into policy or practice 
                    without any consideration of risks of bias, there is a risk that decisions are based on incorrect 
                    results, with the potential for these to translate to poor patient outcomes."/>
                <HomeGridRow icon={GoalImage} iconName="GOAL" subtitle="THE GOAL"
                    text="It is therefore essential that systematic reviews are assessed in terms of potential for bias."/>
            </div>

            {/* WISEST: Ensuring Quality SRs */}
            <div className="large-container" id="wisest-description-box">
                <div className="large-container" id="wisest-description-subtitle">
                    <h2 className="subtitle wisest-subtitle">WISEST: ENSURING QUALITY SRs</h2>
                </div>
                <h4 className="small-text" id="wisest-description-text">
                    The WISEST tool was developed because no tool existed to automatically assess the quality or 
                    risk of biases in this type of evidence synthesis. The WISEST tool assesses the potential risk 
                    of bias or low quality by identifying potential limitations in the way a systematic review was 
                    conducted, including aspects of how the evidence was assembled that may lead to bias in the 
                    systematic review's results or conclusions.
                </h4>
            </div>

            {/* Bottom diagram and link to Developing WISEST page */}
            <div className="button-container">
                <Link to="/DevelopingWisest">
                    <h4 className="button" id="read-more">Read more about how WISEST was developed {'>'}</h4>
                </Link>
            </div>
            <div className="large-container"><img id="ai-vector" src={AIVector} alt="AI"/></div>
        </>
    )
}

export default Home;
