/**
 * Create the React component for generating the current event
 * components using the given event data.
 * 
 * @author Elena Scobici
 */

import React from "react";
import CurrentEvent from './CurrentEvent';

const CurrentEventCreator = ({events, fetchData, setEvents}) => {
    const eventComponents = [];

    events.forEach((event) => {
        eventComponents.push(<CurrentEvent 
            id={event.event_id} 
            name={event.title} 
            description={event.description}
            link={event.link}
            fetchData={fetchData}
            setEvents={setEvents}
            />)
    })
    return (
        <>
        {eventComponents}
        </>
    )
}

export default CurrentEventCreator;