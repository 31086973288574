/**
    Create a React component representing the lesson
    header in the training page.
    This includes the lesson number, title, completion
    status, and the dropdown button to reveal the lesson content.
    The entire header can be click open and close the dropdown.

    @author Sergio Perez
*/

import { FaCheck } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import "./lessonCardHeader.css";

export default function LessonCardHeader({ 
    number, header, dropdownToggler, dropdownOpen, isCompleted, type
}) {
    const lessonNumber = type === 'main' ? number : null;
    const headerTitle = (
        <h2 className="lesson-card-header-title">
            {lessonNumber ? `LESSON ${lessonNumber}: ${header}` : `Optional Lesson: ${header}`}
        </h2>
    );

    const handleClick = () => {
        dropdownToggler(!dropdownOpen);
    };


    return (
        <div className={`lesson-card-header-container-${type}`} onClick={handleClick}>
            {headerTitle}
            <div className="lesson-card-header-right-container">
                <FaCheck className={`lesson-card-header-checkmark${isCompleted ? "-complete" : ""}`} />
                <button className="lesson-card-header-dropdown-button">
                    {dropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </button>
            </div>
        </div>
    );
}