/**
    Create a React component representing a thank you
    message pop up for the About Us page feedback form.
    The user must fill out at least 1 question for
    the form to be submitted successfully and this
    message to appear.

    @author Sergio Perez
*/

export default function ThankYouMessage({ isActive }) {
    let message = []
    if (isActive) {
        message = (
            <div className="thank-you-message-container">
                <p>Thank you for your Contribution!</p>
            </div>
        )
    }
    
    return message
}