/**
 *  Component for the Timeline on the Developing Wiesest Page
 *  @author  Cassandra Tin Kwon Yuen, Aliyah James
 *   */


import React, { useState } from 'react';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component'; 
// react component found here : https://www.npmjs.com/package/react-vertical-timeline-component
import 'react-vertical-timeline-component/style.min.css';
import { FaCircle } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import{ TbProgressCheck } from "react-icons/tb";



export const DevelopingWisestTimeline = () => {  
  const popupSections = [
    { id: 'section-1', popupId: 'citations1' },
    { id: 'section-2', popupId: 'citations2' },
    { id: 'section-3', popupId: 'todo1' },
    { id: 'section-3', popupId: 'todo2' },
  ];

  const [visiblePopup, setVisiblePopup] = useState(null);

  const togglePopup = (popupId) => {
    setVisiblePopup(visiblePopup === popupId ? null : popupId);
  };

    return (
      <>
       <div className='timeline'>       
      <VerticalTimeline        
        lineColor='#b7b7b7'
      >
      <VerticalTimelineElement className="timeline-container-orange"     
        contentStyle={{color:'#000', fontFamily:'Mulish', top: '-18px', background: 'none'}}
        icon={<FaCircle size="20" style={{color: '#fcb178'}}/>} >  
        <div className="timeline-left">
         <h3 className="vertical-timeline-element-title"><bold>A. Identify & Define Problem</bold></h3> 
         <hr className="timeline-line" style={{color: '#fcb178'}}></hr>
         <FaCircle size="12" style={{color: '#fcb178'}} className="timeline-left-circle"></FaCircle>    
        <ul>
          <li>Over 80 SR published/day</li>
          <li>EBM requires quality appraisal which is time consuming and difficult</li>
          <li>Skill, time and cost needed to manually assess</li>
          <li>An automated method for selecting the best evidence does not exist</li>         
        </ul> 
        <p className="timeline-completed" style={{color:'#00b050'}}> 
        <IoIosCheckmarkCircleOutline size="18" style={{color: '#00b050'}}/> Completed
        </p>
        </div>
      </VerticalTimelineElement >

      <VerticalTimelineElement className="timeline-container-yellow"     
        contentStyle={{color:'#000', fontFamily:'Mulish', top: '-18px', background: 'none'}}
        icon={<FaCircle size="20" style={{color: '#fee599'}}/>} >  
        <div className="timeline-right">
         <h3 className="vertical-timeline-element-title"><bold>B. Test existing tool & survey KUs</bold></h3> 
         <hr className="timeline-line" style={{color: '#fcb178'}}></hr>
         <FaCircle size="12" style={{color: '#fee599'}} className="timeline-right-circle"></FaCircle>    
         <ol type='i'>
           <li>Assessed the replicability of the Jadad algorithm. Algorithm not replicable</li>
           <li>Survey users to determine barriers and facilitators to choosing SRs</li>                   
         </ol>          
         
        <div className="popup-container">
        <div id="citations1" className='popup-section'>
          <div className='popup-wrapper' onClick={() => togglePopup('citations1')}>
            Citations 
          </div>
          {visiblePopup === 'citations1' && <span className="popuptext" id="citations1">
          <ul>
              <li>Jadad et al. A guide to interpreting discordant systematic reviews. CMAJ 1997;156(10):1411-6.</li>
              <li>Lunny et al. Protocol for a replication study of the Jadad algorithm. BMJ Open. 2022;12(4).</li>
              <li>Lunny et al. How can clinicians choose between conflicting and discordant systematic reviews? A replication study of the Jadad 
                algorithm. Research synthesis methods.</li>
            </ul>
          </span>}
        </div>
        <p className="timeline-completed" style={{color:'#00b050'}}>         
        <IoIosCheckmarkCircleOutline size="18" style={{color: '#00b050'}}/> Completed
        </p>  
      </div>
    </div>   
      </VerticalTimelineElement>
      <VerticalTimelineElement className="timeline-container-mint"     
        contentStyle={{color:'#000', fontFamily:'Mulish', top: '-18px', background: 'none'}}
        // contentArrowStyle={{ borderBottom: '15px solid', borderBottomColor: '#d3ebe5ff' }}                                  
        icon={<FaCircle size="20" style={{color: '#d3ebe5ff'}}/>} >  
        <div className="timeline-left">
         <h3 className="vertical-timeline-element-title"><bold>C. Select features to include in the models</bold></h3>
         <hr className="timeline-line" style={{color: '#d3ebe5ff'}}></hr>
         <FaCircle size="12" style={{color: '#d3ebe5ff'}} className="timeline-left-circle"></FaCircle>    
          <ul>
            <li>Qualitative study of features extracted by researchers used to select SR-level evidence</li>
            <li>Features chosen based on study i - iii, and mapping of the  AMSTAR/ROBIS items</li>                   
          </ul>          
          <div className="popup-container">
        <div id="citations2" className='popup-section'>
          <div className='popup-wrapper' onClick={() => togglePopup('citations2')}>
            Citations 
          </div>
          {visiblePopup === 'citations2' && <span className="popuptext" id="citations2">
          <ul>
              <li>Lunny et al. Identifying discordance across multiple systematic reviews on the same topic. In progress.</li>
              <li>Lunny et al. How do decision makers use systematic reviews in your decision making or learning: A cross-sectional survey. In progress </li>
            </ul>  
          </span>}
        </div>
        <p className="timeline-completed" style={{color:'#00b050'}}>         
        <IoIosCheckmarkCircleOutline size="18" style={{color: '#00b050'}}/> Completed
        </p>  
      </div>
    </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement className="timeline-container-teal"     
        contentStyle={{color:'#000', fontFamily:'Mulish', top: '-18px', background: 'none'}}
        icon={<FaCircle size="20" style={{color: '#72bda9ff'}}/>} >  
        <div className="timeline-right">
         <h3 className="vertical-timeline-element-title"><bold>D. Create Dataset and Website</bold></h3> 
         <hr className="timeline-line" style={{color: '#72bda9ff'}}></hr>
         <FaCircle size="12" style={{color: '#72bda9ff'}} className="timeline-right-circle"></FaCircle>    
          <ul>
            <li>Recruit volunteer assessors</li>
            <li>Create a dataset of 2000 SRs </li>
            <li>Do a gap analysis on topic, condition and setting</li> 
            <li>Create website</li>                  
          </ul>          
          
          <div className="popup-container">
        <div id="todo1" className='popup-section'>
          <div className='popup-wrapper' onClick={() => togglePopup('todo1')}>
            Work to Date 
          </div>
          {visiblePopup === 'todo1' && <span className="popuptext" id="todo1">
          <ul>
              <li>N = 175 systematic reviews assesed to date</li>
              <li>35 volunteer assessors from Cochrane Engage working on assessments topic.</li>
              <li>7 software students working on the website </li>
            </ul>
          </span>}
        </div>
        <p className="timeline-todo" style={{color:'#999999'}}>         
         <TbProgressCheck size="18" style={{color: '#999999'}}/> Year 1 (2023/4)
         </p> 
      </div>
      </div> 
      </VerticalTimelineElement>

      <VerticalTimelineElement className="timeline-container-purple"     
        contentStyle={{color:'#000', fontFamily:'Mulish', top: '-18px', background: 'none'}}
        icon={<FaCircle size="20" style={{color: '#7b88bcff'}}/>} >  
        <div className="timeline-left">
         <h3 className="vertical-timeline-element-title"><bold>E. Code and test models</bold></h3> 
         <hr className="timeline-line" style={{color: '#7b88bcff'}}></hr>
         <FaCircle size="12" style={{color: '#7b88bcff'}} className="timeline-left-circle"></FaCircle>    
       <ul>
         <li>Code using sample dataset </li>
         <li>Train, test and validate the models using F-score and accuracy</li> 
         <li>Retrain models based on testing performance </li>                  
       </ul>          
       <div className="popup-container">
        <div id="todo2" className='popup-section'>
          <div className='popup-wrapper' onClick={() => togglePopup('todo2')}>
            Work to Date 
          </div>
          {visiblePopup === 'todo2' && <span className="popuptext" id="todo2">
          <ul>
              <li>Code developed for cleaning the data</li>
              <li>Model coding done using Sentence BERT</li>             
               </ul>
          </span>}
        </div>
        <p className="timeline-todo" style={{color:'#999999'}}>         
         <TbProgressCheck size="18" style={{color: '#999999'}}/> Year 1 (2023/4)
         </p> 
      </div>
        </div>
      </VerticalTimelineElement>

      <VerticalTimelineElement className="timeline-container-light-purple"     
        contentStyle={{color:'#000', fontFamily:'Mulish', top: '-18px', background: 'none'}}
        icon={<FaCircle size="20" style={{color: '#bfc8e3'}}/>} >  
        <div className="timeline-right">
         <h3 className="vertical-timeline-element-title"><bold>F. Validate, deploy and evaluate</bold></h3> 
         <hr className="timeline-line" style={{color: '#bfc8e3'}}></hr>
         <FaCircle size="12" style={{color: '#bfc8e3'}} className="timeline-right-circle"></FaCircle>    
         <ul>     
           <li>Validation: Compare human to AI output</li>
           <li>Deploy free and open-access website </li> 
           <li>Evaluation of the tool’s functionality  </li>   
           <li>Conduct usability testing </li>               
         </ul>          
         <p className="timeline-todo" style={{color:'#999999'}}>         
        <TbProgressCheck size="18" style={{color: '#999999'}}/> Years 3+
        </p>           
        </div>
      </VerticalTimelineElement>      
      </VerticalTimeline>
      </div>     
      </>
    );
};

export default DevelopingWisestTimeline;
