import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import userIcon from '../../../images/user-icon.svg'
import passwordIcon from '../../../images/password-icon.svg'
import { AuthContext } from '../AuthContext';
import { BACKEND_URL } from '../../../utils/constants';

export default function LogInForm({ handleWrongCredentials }) {
  const [inputs, setInputs] = useState({});
  const {setIsLoggedIn, setUserName, setUserId, setIsAdmin, setToken} = useContext(AuthContext); // keeps track of whether a user is logged in
  const navigate = useNavigate();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (Object.keys(inputs).length < 2) {
      handleWrongCredentials();
      return;
  }

  try {
      const response = await fetch(`${BACKEND_URL}/auth/login`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              email: inputs.email,
              password: inputs.password
          }),
      });

        const data = await response.json();

        if (response.status === 200 || response.status === 201) {
          console.log(data.message);
          setIsLoggedIn(true);
          setUserName(inputs.email);
          setIsAdmin(data.isAdmin || false);  // Use the isAdmin from the server response
          setUserId(data.userId);
          setToken(data.token);  // Store token
          sessionStorage.setItem('isLoggedIn', 'true');
          sessionStorage.setItem('userName', inputs.email);
          sessionStorage.setItem('userId', data.userId);
          sessionStorage.setItem('isAdmin', data.isAdmin ? 'true' : 'false');
          sessionStorage.setItem('token', data.token);  // Persist token
          navigate(`/`);
        } else if (response.status === 401) {
          console.log(data.message);
          handleWrongCredentials();
        } else {
          console.log('Unknown error occurred');
        }
    } catch (error) {
        console.error('There was an error logging in:', error);
    }
  }

  return (
    <form className='user-form' onSubmit={handleSubmit}>
      <label className='input-container'>
        <input className='form-input'
          type='text' 
          name='email' 
          placeholder='Email'
          value={inputs.email || ''} 
          onChange={handleChange}
        />
        <div className='img-container'>
          <img className='icon' src={ userIcon } alt='email icon'/>
        </div>
      </label>
      <label className='input-container'>
        <input className='form-input'
          type='password'  // Change this from 'text' to 'password'
          name='password' 
          placeholder='Password'
          value={inputs.password || ''} 
          onChange={handleChange}
        />
        <div className='img-container'>
          <img className='icon' src={ passwordIcon } alt='password icon'/>
        </div>
      </label>
      <Link className='reset-password-link' to='/ForgotPassword'>Forgot your password?</Link>
      <input className='submit-user-button' type='submit' value='LOGIN'/>
    </form>
  )
}