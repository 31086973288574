import { useNavigate } from 'react-router-dom';

export default function NavigationButton({ url, text, styleName }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(url);
    }

    return <button 
            className={ styleName }
            onClick={handleClick}>
                { text }
            </button>
}