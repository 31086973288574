/**
    Create a React component representing the title banner
    for the About Us page.

    @author Sergio Perez
*/

import { GoPeople } from "react-icons/go";
import { IoMailOutline } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";


export default function Banner({ text }) {
    return (
        <div className="banner-container">
            <div className="banner-text" >{ text }</div>
            <div className="banner-icon"><GoPeople id="banner-people" /></div>
            <div className="banner-icon"><IoMailOutline id="banner-mail" /></div>
            <div className="banner-icon"><BsTelephone  id="banner-phone" /></div>
        </div>
        
    )
}