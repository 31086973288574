/**
    Create a React component representing an error message
    if the user did not fill out any question on 
    the About Us page feedback form.

    @author Sergio Perez
*/

export default function ErrorMessage({ isActive, text }) {
    let message = []
    if (isActive) {
        message = (
            <div className="error-message-container">
                <p>{ text }</p>
            </div>
        )
    }

    return message
}