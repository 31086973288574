/** 
    Create a React component representing the detailed results
    generated from the WISEST tool. This is the component
    that will be included in the results export.
    This includes the questions and responses from the
    ROBIS tool and AMSTAR 2 tool.
    The component is designed to specifically fit on A4 paper.

     @author Cassandra Tin Kwon Yuen
*/

import React, { useRef, useEffect } from 'react';
import "./ExportResults.css";


const DetailedPage = ({detailed}) => {   
    return (
      <div id='detailed-export'>
          <>
          <div className="detailed-container-export">
            <div className='report-title-export detailed-subheading-export'>
                <div className="export-text">ROBIS Results</div></div>
            <div className="detailed-report-container-export">
            {Object.entries(detailed.ROBIS || {}).map(([key, value]) => (
              <>
                <div className="accordian-export">
                    <div className="questions-export">
                      <div className='accordian-question-number-export export-text'>{`Q.${key}`}</div> 
                      <div className="accordian-question-export export-text">{`${value.question}`}</div>
                    </div>
                    <p className='accordion-response-export export-text'><b>Response: </b>{value.response}</p>
                    <p className='accordion-quote-export export-text'><b>Quote: </b>{value.quote}</p>
                </div>

              </>
            ))}
            </div>
            </div>
            <div className="detailed-container-export">
            <div className='report-title-export detailed-subheading-export'>
                <div className="export-text">AMSTAR Results</div></div>
            <div className="detailed-report-container-export">
            {Object.entries(detailed.AMSTAR || {}).map(([key, value]) => (
              <>
                <div className="accordian-export">
                    <div className="questions-export">
                      <div className='accordian-question-number-export export-text'>{`Q.${key}`}</div> 
                      <div className="accordian-question-export export-text">{`${value.question}`}</div>
                    </div>
                    <p className='accordion-response-export export-text'><b>Response: </b>{value.response}</p>
                    <p className='accordion-quote-export export-text'><b>Quote: </b>{value.quote}</p>
                </div>

              </>
            ))}
            </div>
            </div>
          </>
        
      </div>
    );
  }
export default DetailedPage;
