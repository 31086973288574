import { IoIosMail } from "react-icons/io";
import caroleLunnyImage from "../images/carole_lunny.png";
import andreaTriccoImage from "../images/andrea_tricco.jpg";
import brianHuttonImage from "../images/brian_hutton.png";
import kevinSunImage from '../images/kevin_sun.jpeg';
import radinRadImage from '../images/radin_rad.jpg';
import halitDincerImage from '../images/halit_dincer.jpg';
import adamAziziImage from '../images/adam_azizi.jpeg';
import ebrahimBagheriImage from '../images/ebrahim_bagheri.jpg' ;
import wisestDevelopersImage from "../images/ai.png"
import studentsImage from "../images/coding.png"
import collaboratorsImage from "../images/handshake.png"

export const BACKEND_URL = "https://wisest.ls3.rnet.torontomu.ca/api";
//export const BACKEND_URL = "http://127.0.0.1:5001";

export const AUTHENTICATION_PAGES = [
    "/LogIn",
    "/SignUp",
    "/ResetPassword",
]
export const MAIN_PAGES = [
    "/",
    "/Home",
    "/UserDashboard",
    "/AdminDashboard",
    "/WisestTool",
    "/Results",
    "/Events", 
    "/DevelopingWisest",
    "/Training",
    "/AboutUs",
    "/Feedback"
]

export const FEEDBACK_CHAR_LIMIT = 255;

export const CONTACT_INFO = {

    "Email": {
        info: "wisest.ai.team@gmail.com",
        icon: <IoIosMail />,
    },
}

export const TEAMS = {
    "Steering Committee": [
        {
            "name": "Carole Lunny",
            "picture": caroleLunnyImage,
            "description": "Chair and Principal Investigator",
        },
        {
            "name": "Andrea Tricco",
            "picture": andreaTriccoImage,
            "description": null,
        },
        {
            "name": "Brian Hutton",
            "picture": brianHuttonImage,
            "description": null,
        },
        {
            "name": "Ebrahim Bagheri",
            "picture": ebrahimBagheriImage,
            "description": "Canada Research Chair in Social Information Retrieval",
        },
        
    ],
    "WISEST Developers": [
        {
            "name": "Radin Rad",
            "picture": radinRadImage,
            "description": "Part-time PhD Candidate in Computer Science and NLP",
        },
        {
            "name": "Kevin Sun",
            "picture": kevinSunImage,
            "description": "Data Science Student",
        },

    ],
    "Web Developers": [
        {
            "name": "Adam Azizi",
            "picture": adamAziziImage,
            "description": "Computer Engineering Student",
        },
        {
            "name": "Halit Dincer",
            "picture": halitDincerImage,
            "description": "Computer Science Student",
        },
    ],
    "Collaborators": [
        {
            "name": "Mohammad Najm Dadam",
            "description": null,
        },
        {
            "name": "Ben Ridley",
            "description": null,
        },
        {
            "name": "Gioia Dahdal",
            "description": null,
        },
        {
            "name": "Maria Cespedes",
            "description": null,
        },
        {
            "name": "Melodi Kosaner Kliess",
            "description": null,
        },
        {
            "name": "Tina Nazari",
            "description": null,
        },
        {
            "name": "Nityanand Jain",
            "description": null,
        },
        {
            "name": "Lucas Santos",
            "description": null,
        },
        {
            "name": "Ian Goodman",
            "description": null,
        },
        {
            "name": "Shailesh Kolekar",
            "description": null,
        },
        {
            "name": "Iván Pérez-Neri",
            "description": null,
        },
        {
            "name": "Alaa Awadallah Mohammedalhassan Osman",
            "description": null,
        },
        {
            "name": "Connor Brenna",
            "description": null,
        },
        {
            "name": "Heba Hussein",
            "description": null,
        },
        {
            "name": "Sanabel Barakat",
            "description": null,
        },
        {
            "name": "Elaine Leang",
            "description": null,
        },
        {
            "name": "Zihui Ouyang",
            "description": null,
        },
        {
            "name": "Janelle Cheung",
            "description": null,
        },
        {
            "name": "Yevhenii Nefedov",
            "description": null,
        },
        {
            "name": "Ke Zhang",
            "description": null,
        },
        {
            "name": "Clara Tam",
            "description": null,
        },
        {
            "name": "Amina Berour",
            "description": null,
        },
    ],
    "University of Toronto Web App Developers": [
        {
            "name": "Malaikah Hussain",
            "description": null,
        },
        {
            "name": "Aliyah James",
            "description": null,
        },
        {
            "name": "Alessandro Marmi",
            "description": null,
        },
        {
            "name": "Sergio Perez",
            "description": null,
        },
        {
            "name": "Komal Saini",
            "description": null,
        },
        {
            "name": "Elena Scobici",
            "description": null,
        },
        {
            "name": "Cassandra Tin Kwon Yuen",
            "description": null,
        },
    ],

}

export const SAMPLE_EVENTS = [
    {
        title: "Sample Generic Event",
        date: new Date("2023-01-01"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Long Event",
        date: new Date("2023-02-01"),
        description: "This is a sample event with a long description that overflows the preview. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed consectetur leo. Praesent cursus aliquet ex. Quisque lorem odio, convallis vel massa in, tincidunt suscipit quam. \
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed consectetur leo. Praesent cursus aliquet ex. Quisque lorem odio, convallis vel massa in, tincidunt suscipit quam.",
        type: "Other"
    },
    {
        title: "Sample Webinar Event",
        date: new Date("2023-01-02"),
        description: "This is a sample event of type Webinar.",
        type: "Webinar"
    },
    {
        title: "Sample Training Event",
        date: new Date("2023-02-01"),
        description: "This is a sample event of type Training.",
        type: "Training"
    },
    {
        title: "Sample Workshop Event",
        date: new Date("2022-01-02"),
        description: "This is a sample event of type Workshop.",
        type: "Workshop"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2022-02-01"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2022-01-02"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2022-02-01"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2021-01-02"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2021-02-01"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2021-01-02"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2021-02-01"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2021-01-02"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2021-01-01"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2021-01-01"),
        description: "This is a sample event.",
        type: "Other"
    },
    {
        title: "Sample Generic Event",
        date: new Date("2021-01-01"),
        description: "This is a sample event.",
        type: "Other"
    },
]

export const EVENT_TYPES = [
    "Webinar",
    "Training",
    "Workshop",
    "Other"
]

export const TRAINING_BOTTOM_PARAGRAPH = "Our confidence in the results of research studies is affected by how they are designed, conducted and reported. In ideal circumstances, all studies would accurately determine the true effect of a health care intervention. However, studies do not take place in an ideal world, and flaws or problems in the way they are conducted could lead to them either overestimating or underestimating the true effect of the intervention.\n\nUnderstanding the potential flaws or problems in studies included in systematic reviews is important as they can distort or bias the results, which means the findings might not accurately represent the truth. Assessing the design, conduct and reporting of studies included in Cochrane Reviews is therefore an important step in understanding whether there is any risk of bias in the results, supporting our confidence in the review's conclusions."