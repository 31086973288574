import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import passwordIcon from '../../../images/password-icon.svg';
import { BACKEND_URL } from '../../../utils/constants';

export default function ResetPasswordForm({ handleDiffPasswords, handleWrongEmail, handleEmptyFields }) {
  const [inputs, setInputs] = useState({});
  const [errorMessage, setErrorMessage] = useState(''); // State to handle error messages
  const navigate = useNavigate();
  const { token } = useParams(); // Extract the token from the URL

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
    setErrorMessage(''); // Clear error message when user starts typing again
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const samePasswords = inputs.password === inputs.passwordConfirmation;
    const nonEmptyFields = inputs.password && inputs.passwordConfirmation;

    handleDiffPasswords(!samePasswords);
    handleEmptyFields(!nonEmptyFields);

    if (samePasswords && nonEmptyFields) {
      try {
        const response = await fetch(`${BACKEND_URL}/auth/reset-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token, newPassword: inputs.password }),
        });
        const data = await response.json();
        if (response.ok) {
          navigate('/login');
        } else {
          setErrorMessage(data.message || 'An error occurred while resetting the password.');
        }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('An error occurred.');
      }
    } else {
      setErrorMessage('Please ensure the fields are filled correctly.');
    }
  };

  return (
    <form className='user-form' onSubmit={handleSubmit}>
      <label className='input-container'>
        <input className='form-input'
          type='password' 
          name='password' 
          placeholder='New Password'
          value={inputs.password || ''} 
          onChange={handleChange}
        />
        <div className='img-container'>
          <img className='icon' src={passwordIcon} alt='password icon'/>
        </div>
      </label>
      <label className='input-container'>
        <input className='form-input'
          type='password' 
          name='passwordConfirmation' 
          placeholder='Confirm Password'
          value={inputs.passwordConfirmation || ''} 
          onChange={handleChange}
        />
        <div className='img-container'>
          <img className='icon' src={passwordIcon} alt='password icon'/>
        </div>
      </label>
      <input className='submit-user-button' type='submit' value='Reset Password'/>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </form>
  );
}
