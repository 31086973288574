import { getFormattedDate } from "./ListElements/eventCardFunctions";

export function filterEventsByType(events, activeTypeFilters) {
    const filteredEvents = events.filter((event) => {
        return activeTypeFilters.includes(event.type);
    });
    return filteredEvents;
}

export function filterEventsByDate(events, dateFilter) {
    const filteredEvents = events.filter((event) => {
        return getFormattedDate(event.date) === getFormattedDate(dateFilter);
    });
    return filteredEvents;
}

export function filterEventsBySearch(events, searchFilter) {
    const lowerCaseSearchFilter = searchFilter.toLowerCase();
    const filteredEvents = events.filter((event) => {
        
        const titleMatch = event.title && event.title.toLowerCase().includes(lowerCaseSearchFilter);
        const descriptionMatch = event.description && event.description.toLowerCase().includes(lowerCaseSearchFilter);
        const linkMatch = event.link && event.link.toLowerCase().includes(lowerCaseSearchFilter);
        
        return titleMatch || descriptionMatch || linkMatch;
    });
    return filteredEvents;
}