/**
    Create a React component representing the contact 
    us section. The information and icons are
    from constants.js.

    @author Cassandra Tin Kwon Yuen, Sergio Perez
*/

import ContactField from "./ContactField";
import { CONTACT_INFO } from "../../../utils/constants"


export default function ContactInfo() {

    const contactInfo = []
    for (const fieldName in CONTACT_INFO) {
        contactInfo.push(
            <ContactField
                fieldName={fieldName}
                fieldInfo={CONTACT_INFO[fieldName].info}
                fieldImage={CONTACT_INFO[fieldName].icon}
            />
        )
    }

    return <div className="contact-info">
        { contactInfo }
    </div>
}