import './App.css';
import LogIn from './components/AuthenticationPages/LogIn/LogIn';
import SignUp from './components/AuthenticationPages/SignUp/SignUp';
import Navbar from "./components/Navbar";
import Footer from './components/Footer/Footer';
import UserDashboard from './components/UserDashboard/UserDashboard';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import Home from './components/Home/Home';
import AboutUs from './components/AboutUsPage/AboutUs';
import WisestTool from './components/WisestTool/WisestTool';
import LoadingPage from './components/WisestTool/LoadingPage';
import ResultsPage from './components/ResultsPage/ResultsPage';
import EventsPage from './components/EventsPage/EventsPage';
import TrainingPage from './components/TrainingPage/TrainingPage';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { MAIN_PAGES } from './utils/constants';
import { AuthProvider } from './components/AuthenticationPages/AuthContext';
import DevelopingWisest from './components/DevelopingWisest/DevelopingWisest';
import ForgotPassword from './components/AuthenticationPages/PasswordChangeComponents/ForgotPassword';
import SetNewPassword from './components/AuthenticationPages/PasswordChangeComponents/SetNewPassword';
import FeedbackPage from './components/FeedbackPage/FeedbackPage';

function App() {
  const location = useLocation();
  const inMainPage = MAIN_PAGES.includes(location.pathname)
  const [isMainPage, setIsMainPage] = useState(inMainPage);

  useEffect(() => {
      setIsMainPage(inMainPage)

      if (!inMainPage && location.pathname !== '/Loading') {
        // Then we're in an authentication page
        document.body.classList.add('authentication-page');
      }
      else {
        document.body.classList.remove('authentication-page');
      }
    },
    [location]
  );

  // auth provider allows classes within to know whether the user is logged in
  return (
    <AuthProvider>
    <>
        <Navbar isActive={ isMainPage }/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/UserDashboard" element={<UserDashboard/>}/>
          <Route path="/AdminDashboard" element={<AdminDashboard/>}/>
          <Route path="/AboutUs" element={<AboutUs/>}/>
          <Route path="/Feedback" element={<FeedbackPage/>}/>
          <Route path="/Home" element={<Home/>}/>
          <Route path="/DevelopingWisest" element={<DevelopingWisest/>}/>
          <Route path="/LogIn" element={<LogIn/>}/>
          <Route path="/SignUp" element={<SignUp/>}/>
          <Route path="/Events" element={<EventsPage/>}/>
          <Route path="/Training" element={<TrainingPage/>}/>
          <Route path="/WisestTool" element={<WisestTool/>}/>
          <Route path="/Loading" element={<LoadingPage/>}/>
          <Route path="/Results" element={<ResultsPage/>}/>
          <Route path="/ForgotPassword" element={<ForgotPassword/>}/>
          <Route path="/SetNewPassword/:token" element={<SetNewPassword/>}/>
        </Routes>
        <Footer isActive={ isMainPage }/>       
    </>
    </AuthProvider>
  );
}

export default App;
