import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import Background from '../MiscElements/Background';
import '../generalStyle.css';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { BACKEND_URL } from '../../../utils/constants';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');  // New state for the response message
    const { userName } = useContext(AuthContext); 

    const handleChange = (event) => {
        setEmail(event.target.value);
        setResponseMessage('');  // Clear response message when email is changed
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!/\S+@\S+\.\S+/.test(email)) {
            setResponseMessage('Please enter a valid email address.');
            return;
        }
        try {
            const response = await fetch(`${BACKEND_URL}/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            setResponseMessage(data.message);  // Set the response message
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('An error occurred.');
        }
    };

    return (
        <>
            <div id='general'>
            <Background />
            <Link to="/" className='home-icon'>
                <FaHome url='/'/>
            </Link>
            <div className='container'>
                <form className='user-form' onSubmit={handleSubmit}>
                    <label className='input-container'>
                        <input 
                            className='form-input'
                            type='email' 
                            name='email' 
                            placeholder='Email'
                            value={email} 
                            onChange={handleChange}
                        />
                    </label>
                    <button type='submit' className='submit-user-button'>Send Email</button>
                </form>
                {/* ADD STYLING FOR RESPONSE MESSAGE! */}
                {responseMessage && <p className="response-message">{responseMessage}</p>}  {/* Display the response message */}
            </div>
            </div>
        </>
    );
}

export default ForgotPassword;
