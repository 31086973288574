/**
 * Create the Profile React component, used on both the user dashboard
 * and admin dashboard pages.
 * 
 * @author Elena Scobici, Alessandro Marmi
 */

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import './UserDashboard.css';
import userProfileIcon from '../../images/user-profile.png';
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from '../../utils/constants'
import { AuthContext } from '../AuthenticationPages/AuthContext';
import { MdOutlineEdit } from "react-icons/md";

const Profile = ({ userName, isLoggedIn, logoutUser, setIsModalOpen }) => {
    const [name, setName] = useState({ firstName: '', lastName: '' });
    const [originalName, setOriginalName] = useState({ firstName: '', lastName: '' });
    const [profilePic, setProfilePic] = useState(userProfileIcon);
    const [file, setFile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const {token, isAdmin} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
        } else {
            axios.get(`${BACKEND_URL}/get-name?userName=${userName}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                const userData = response.data;
                const fetchedName = { firstName: userData.first_name, lastName: userData.last_name };
                setName(fetchedName);
                setOriginalName(fetchedName);
                if (userData.profile_picture) {
                    setProfilePic(`${BACKEND_URL}/${userData.profile_picture}`);
                } else {
                    setProfilePic(userProfileIcon);  // Set default icon if no profile picture
                }
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
                if (error.response && error.response.status === 401) {
                    logoutUser();  // Logs out the user if the response status is 401
                    navigate('/login');  // Redirects user to the login page
                }
            });
        }
    }, [userName, isLoggedIn, navigate, token, logoutUser]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        const formData = new FormData();
        formData.append('userName', userName);
        formData.append('firstName', name.firstName);
        formData.append('lastName', name.lastName);
    
        if (file) {
            formData.append('profilePicture', file, file.name);
        }
    
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        };
    
        axios.post(`${BACKEND_URL}/save-profile-changes`, formData, { headers })
            .then(response => {
                console.log('Profile updated successfully', response);
                setOriginalName({ firstName: name.firstName, lastName: name.lastName });
                setIsEditing(false);
            })
            .catch(error => {
                console.error('Error updating profile:', error);
            });
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setName(originalName);
    };

    const handleNameChange = (e) => {
        setName({ ...name, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            const uploadedFile = e.target.files[0];
            setFile(uploadedFile);
            const reader = new FileReader();
            reader.onload = function (upload) {
                setProfilePic(upload.target.result);
            };
            reader.readAsDataURL(uploadedFile);
        }
    };

    return (
        <div className="profile-container">
            <div className="profile-pic-container">
                <div className='profile-pic-wrapper'>
                    <img className="profile-pic" src={profilePic} alt="Profile image" />
                </div>
                {isEditing &&
                    <div className="profile-pic-edit">
                        <input id="profile-pic-edit-input" type='file' className="profile-pic-edit-upload" accept=".png, .jpg, .jpeg" onChange={handleFileChange} />
                        <label htmlFor="profile-pic-edit-input" className="profile-pic-edit-label"><MdOutlineEdit /></label>
                    </div>
                }
            </div>
            <div className="profile-info-container">
                {isEditing ? (
                    <>
                        <input 
                            className="profile-name-input"
                            style={{ width: '150px' }} 
                            placeholder="First Name.."
                            value={name.firstName}
                            onChange={handleNameChange}
                            name="firstName"
                        />
                        <input 
                            className="profile-name-input"
                            style={{ width: '150px' }} 
                            placeholder="Last Name.."
                            value={name.lastName}
                            onChange={handleNameChange}
                            name="lastName"
                        />
                    </>
                ) : (
                    <p className="profile-name">{`${name.firstName} ${name.lastName}`}</p>
                )}
                <p className="profile-email">
                    Email: <a className="email-link">{userName}</a>
                </p>
                <p className="password">Password: *****</p>
                <div className='edit-buttons-container'>
                    {isEditing ? (
                        <>
                            <div className="edit-profile-button" onClick={handleSaveClick}>
                                Save Changes
                            </div>
                            <div className="cancel-button" onClick={handleCancelClick}>
                                Cancel
                            </div>
                        </>
                    ) : (
                        <div className="edit-profile-button" onClick={handleEditClick}>
                            Edit Profile
                        </div>
                    )}
                    { isAdmin && !isEditing &&
                        <div className="edit-profile-button" onClick={() => setIsModalOpen(true)}>
                            Manage Admins
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Profile;
