/**
    Create a React component representing each team list
    on the About Us Page. These lists only contain the team
    name and the names of each member - there is no associated
    image or description.

    @author Cassandra Tin Kwon Yuen
*/

import TeamBanner from "./TeamBanner";

export default function TeamList({ teamName, teamMembers }) {
    const teamPicture = teamMembers.find(member => member["team-picture"]);
    const members = teamMembers.filter(member => !member["team-picture"]);

    let membersColumn1 = [];
    let membersColumn2 = [];

    if (members.length > 10) {
        membersColumn1 = members.slice(0, Math.ceil(members.length / 2));
        membersColumn2 = members.slice(Math.ceil(members.length / 2));
    } else {
        membersColumn1 = members;
    }

    return (
        <div className="team-list-container">
            <TeamBanner teamName={teamName} list={true} />
            {teamPicture && (
                <img src={teamPicture["team-picture"]} alt={teamName} className="team-list-picture" />
            )}
            <div className="team-list-wrapper">
                <div className="column">
                    {membersColumn1.map((teamMember) => (
                        <div key={teamMember.name} className="list-member">
                            {teamMember.name}
                        </div>
                    ))}
                </div>
                {membersColumn2.length > 0 && (
                    <div className="column">
                        {membersColumn2.map((teamMember) => (
                            <div key={teamMember.name} className="list-member">
                                {teamMember.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}