/**
 * Create the React component for the user dashboard page.
 *
 * @author Elena Scobici
 */

import React, {useContext, useState, useEffect} from 'react';
import './UserDashboard.css';
import UserUploads from "./UploadedFiles"
import NumberSessions from "./NumberSessions"
import {AuthContext} from "../AuthenticationPages/AuthContext"
import Profile from "./Profile"
import { BACKEND_URL } from '../../utils/constants'
import { useNavigate } from 'react-router-dom';

const UserDashboard = () => {
    let currentlyLargeScreen = window.innerWidth >= 1000;

    // tracks whether someone is logged in and their username (if they are)
    const { isLoggedIn, userName, userId, logoutUser, token } = useContext(AuthContext);

    const navigate = useNavigate();

    // Make the background light grey
    document.body.style.backgroundColor = "#fafafe";

    // Set the minHeight of the page container to make the footer 
    // properly align with the page bottom.
    let pageContainerStyle = {
        minHeight: 'calc(100vh - 100px - ' + 
            String(document.querySelector('.page-container')?.
                offsetTop) + 'px)',
    };

    // Helper method for calculating and applying new min height for
    // page-container based on its top offset.
    function updatePageContainerMinHeight() {
        currentlyLargeScreen = !currentlyLargeScreen;
        const pageContainer = document.
            querySelector('.page-container');
        if (pageContainer !== null) {
            pageContainer.style.minHeight = 'calc(100vh - 100px - ' + 
                String(pageContainer.offsetTop) + 'px)';
        }
    }

    // Change page-container height when navbar height switches,
    // at 1000 px viewport width.
    function handleResize() {
        if (window.innerWidth < 1000 == currentlyLargeScreen) {
            updatePageContainerMinHeight();
        }
    }

    // Listen for window resize and component loading so that
    // page-container minHeight can be updated.
    window.addEventListener('resize', handleResize);
    React.useEffect(() => {
        updatePageContainerMinHeight();
    });

    // Get the user's uploaded files from the server.
    const [uploads, setUploads] = useState([]);

    async function fetchData() {
        try {
            const encodedUserId = encodeURIComponent(userId); // Assuming you have user_id from userName context
            const url = `${BACKEND_URL}/analyses?user_id=${encodedUserId}`; 
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    logoutUser();
                    navigate('/login');
                    return;
                }
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setUploads(data);
            console.log(data);
        } catch (error) {
            console.error('Error fetching analyses:', error);
        }
    }

    // Re-fetch the upload data when the username or login status
    // changes.
    useEffect(() => {
    //   if (isLoggedIn) {
        fetchData();
    }, [userId]);

    // isLoggedIn, userName

    return (
        <div class="page-container" style={pageContainerStyle}>
            <div class="profile-and-sessions-container">  
                <Profile userName={userName} isLoggedIn={isLoggedIn} logoutUser={logoutUser} ></Profile>
                <div class="sessions-container">
                    <p class="number-sessions-text"># of Sessions:</p>
                    <NumberSessions uploads={uploads}/>
                </div>
            </div>
            <div class="dashboard-container">
                <h1 id="uploads-text">Uploads</h1>
                <UserUploads uploads={uploads} fetchData={() => fetchData()}></UserUploads>
            </div>
        </div>
    )
}

export default UserDashboard;
