/**
 * Create the React conmponent for displaying the number of sessions.
 * 
 * @author Elena Scobici
 */

import React from 'react';

function NumberSessions({uploads}) {
    return (
        // Display all file names in frontend
        <div>
            <p class="sessions-number">{uploads ? uploads.length : 0}</p>
        </div>
    );
}

export default NumberSessions;