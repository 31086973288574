
export function getNumberOfEventsToShow() {
  const width = window.innerWidth;
  if (width > 1440) {
    return 10;
  } else if (width > 1024) {
    return 6;
  } else if (width > 768) {
    return 4;
  } else {
    return 3;
  }
}