/**
    Create the React component for the sessions displayed on the
    user dashboard page.

    @author Elena Scobici
*/

import {React, useContext, useState} from 'react';
import './SessionContainer.css';
import exportIcon from '../../images/export-button.png';
import { Link } from 'react-router-dom';
import { BACKEND_URL } from '../../utils/constants'
import { AuthContext } from '../AuthenticationPages/AuthContext';

const SessionContainer = ({analysisId, fileName, date, summarizedResult, detailedResult, fetchData}) => {
    const { token } = useContext(AuthContext);

    const removeSession = () => {
        if (analysisId !== undefined) {
            fetch(`${BACKEND_URL}/analyses/${analysisId}`, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((response) => {
                if (response.ok) { // This checks for any 2xx success status.
                    fetchData(); // Re-fetch the user's uploads data.
                } else {
                    console.error("Error status: " + response.status);
                }
            }).catch((error) => {
                console.error("Network error: ", error);
            });
        } else {
            console.error("Analysis ID is not defined.");
        }
    };

    const result = {
        'result': {
            'summary': summarizedResult,
            'detailed': detailedResult
        },
        'fileUploaded': {
            'name': fileName
        }
    }

    return (
        <div className="session-container-box">
            <div className="file-name-container">
                <Link className="file-info" to='/Results' state={result}>
                    {fileName} [{date}]
                </Link>
                <div className="buttons-container">
                    <div className="delete-button"
                        onClick={removeSession}>x
                    </div>
                    <div className="bottom-buttons">
                        <div className="feedback-button buttons">
                            <a className="buttons-text">
                                Feedback
                            </a>
                        </div>
                        <div className="export-button buttons">
                            <Link to='/Results#export-section' state={result}>
                                <span className='buttons-text'>Export</span><img className="export-icon" alt="Export icon" src={exportIcon}></img>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionContainer;