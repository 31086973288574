import styled from "styled-components";


export const ResultContainer = styled.div`
    display: flex;
    position: relative;
    align-items: stretch;
    min-height: 100vh;
`;

export const Card = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    max-height: 85vh;
`;

export const Heading = styled.h2`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const SubHeading = styled.h3`
    font-size: 20px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 10px;
`;

export const Text = styled.p`
    font-size: 16px;
    margin-bottom: 5px;
    span.negative {
        color: red;
    }
    span.positive {
        color: green;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin: 0;
    padding: 0;
`;

export const ExportButton = styled.button`
    display: inline-flex; /* Ensures the button is flexible but inline */
    flex-shrink: 0; /* Prevents the button from shrinking */
    background: var(--purple);
    font-family: Mulish;
    font-size: 1.2rem;
    padding: 10px 20px;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        background: #596AAE;
    }
`;
