/**
    Create a React component representing the training page.
    This is the general structure of the page, including the
    banner at the top, a link to the main resources, the 
    list of lessons, and a description paragraph at the bottom.

    @author Sergio Perez, Cassandra Tin Kwon Yuen
*/

import { Link } from 'react-router-dom';
import TrainingBanner from './TrainingElements/Banner/TrainingBanner';
import LessonList from './TrainingElements/Lessons/LessonList';
import { TRAINING_BOTTOM_PARAGRAPH } from '../../utils/constants';
import './trainingPage.css';

export default function Training() {
  return (
    <>
        <TrainingBanner />
        <div className="training-top-paragraph-container">
          <h2>
            To access to the training material, including exercises and modules, please visit our{' '}
            <Link to="https://osf.io/7vg3w/" className="extra-training-link" target="_blank">
              OSF Page
            </Link>
          </h2>
          <h2 className="training-instruction">Click on the checkmarks to save your progress if logged in</h2>
        </div>
        <LessonList />
        <div className="training-bottom-paragraph-container">
            <p>
                {TRAINING_BOTTOM_PARAGRAPH}
            </p>
        </div>
    </>
  );
}