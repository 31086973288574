/**
    Create a React component representing a character counter
    for the About Us page feedback input boxes.
    FEEDBACK_CHAR_LIMIT is 255 characters.

    @author Sergio Perez
*/


import React from 'react';
import { FEEDBACK_CHAR_LIMIT } from "../../../utils/constants";

export default function CharacterCounter({ text }) {
  const textLength = text.length;

  return (
    <p className="character-counter-text">
      {textLength} / {FEEDBACK_CHAR_LIMIT}
    </p>
  );
}
