/**
    Create a React component representing all teams 
    on the About Us Page. This contains all team sections
    and each member's relevant information from constants.js.

    @author Cassandra Tin Kwon Yuen, Sergio Perez
*/

import TeamCard from "./TeamSection"
import TeamList from "./TeamList";
import { TEAMS } from "../../../utils/constants"

export default function Teams() {

    const teams = []
    for (const teamName in TEAMS) {
        const teamMembers = TEAMS[teamName];
        const hasPicture = teamMembers.some(member => member.picture);

        const teamComponent = hasPicture ? (
            <TeamCard teamName={teamName} teamMembers={teamMembers} />
        ) : (
            <TeamList teamName={teamName} teamMembers={teamMembers} />
        );

        teams.push(teamComponent);
    }

    return (
        <div className="teams-container">
            { teams }
        </div>
    )
}