/**
   Create a react component representing the navigation bar.
   Contains the relevant links of the website.
   If the user is not logged in, they will see "Log In" and "Sign Up"
   at the top right.
   Otherwise, they will see "Dashboard" and "Sign Out".
   Depending on the type of user (regular or admin), the user
   will see their relevant dashboard.

    @author Alessandro Marmi, Cassandra Tin Kwon Yuen
*/

import {React, useState, useEffect, useContext} from 'react';
import { NavLink } from "react-router-dom";
import {Nav, StyledNavLink,  Bars, NavMenu, NavLogo, NavBtn, NavBtnLink, Sidebar, LogoLink} from "./NavbarElements";
import Logo from "./Logo";
import { AuthContext } from '../AuthenticationPages/AuthContext';


// Define all the static links that are visible to all users
const links = [
    { to: '/Home', label: 'Home' },
    { to: '/AboutUs', label: 'About Us'}, 
    { to: '/Events', label: 'Media' },
    { to: '/DevelopingWisest', label: 'Developing WISEST' },
    { to: '/Training', label: 'Training' },
];


function CustomLink(props) {
    const { isLoggedIn, setIsLoggedIn, setUserName, isAdmin, setIsAdmin } = useContext(AuthContext);
    const handleLogout = () => {
        // Add any additional logout logic here if needed
        setIsLoggedIn(false); // This will set isLoggedIn to false
        setIsAdmin(false);
        setUserName('');
        sessionStorage.removeItem('isLoggedIn');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('isAdmin');
    };    

    if (isLoggedIn && isAdmin) {
        return(
            <>
                <NavBtnLink to="/WisestTool" id="wisest-tool">
                    Try Wisest
                </NavBtnLink>
                <NavBtnLink to="/AdminDashboard" id="dashboard">
                    Dashboard
                </NavBtnLink>
                <NavBtnLink to="/" id="log-out" onClick={handleLogout}>
                    Log Out
                </NavBtnLink>

        </>)
        }
        else if (isLoggedIn && !isAdmin){
            return (
                <>
                    <NavBtnLink to="/WisestTool" id="wisest-tool">
                        Try Wisest
                    </NavBtnLink>
                    <NavBtnLink to="/UserDashboard" id="dashboard">
                        Dashboard
                    </NavBtnLink>
                    <NavBtnLink to="/" id="log-out" onClick={handleLogout}>
                        Log Out
                    </NavBtnLink>
                </>
            )
        }else{
            return( 
                <>
                        <NavBtnLink to="/LogIn" id="log-in">
                            Log In
                        </NavBtnLink>
                        <NavBtnLink to="/SignUp" id="sign-up">
                            Sign Up
                        </NavBtnLink>
                    </>
            )
        }
    }

const Navbar = ({ isActive }) => {
    const [isSidebarOpen, setSidebarOpen] = useState(false); 
    const { isLoggedIn, isAdmin, setIsLoggedIn, setIsAdmin, setUserName } = useContext(AuthContext);
    
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1000) {
                setSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    let navbar = []
    if (isActive) {
        navbar = (
            <>
                <NavLogo>
                    <LogoLink to="/">
                        <Logo/>
                    </LogoLink>
                    <NavBtn>
                        <CustomLink />
                    </NavBtn>
                </NavLogo>
                <Nav> 
                    <Bars onClick={toggleSidebar} />
                    <NavMenu>
                        {links.map(link => (
                            <StyledNavLink key={link.to} to={link.to}>
                                {link.label}
                            </StyledNavLink>
                        ))}
                        {isLoggedIn && (
                            <StyledNavLink to="/Feedback" id="feedback">
                                Feedback
                            </StyledNavLink>
                        )}
                    </NavMenu>
                </Nav>

                {isSidebarOpen && (
                    <Sidebar sidebarIsOpen={isSidebarOpen}>
                        {links.map(link => (
                            <NavLink key={link.to} to={link.to}>
                                {link.label}
                            </NavLink>
                        ))}
                        {isLoggedIn && (
                            <NavLink to="/Feedback" id="feedback" onClick={() => setSidebarOpen(false)}>
                                Feedback
                            </NavLink>
                        )}
                        <CustomLink />
                    </Sidebar>
                )}
            </>
        )
    }

    return navbar
}

export default Navbar;
