import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import userIcon from '../../../images/user-icon.svg';
import passwordIcon from '../../../images/password-icon.svg';
import { AuthContext } from '../AuthContext';
import { BACKEND_URL } from '../../../utils/constants';

export default function SignUpForm({ handleEmailAlreadyExists, handleDiffPasswords, handleWeakPassword }) {
  const [inputs, setInputs] = useState({});
  const navigate = useNavigate();
  const { setIsLoggedIn, setUserName, setUserId, setIsAdmin } = useContext(AuthContext);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newEmail = inputs.email !== 'test@email.com';
    const samePasswords = inputs.password === inputs.passwordConfirmation;
    const isPasswordStrong = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(inputs.password);

    handleDiffPasswords(!samePasswords);
    handleEmailAlreadyExists(!newEmail);
    handleWeakPassword(!isPasswordStrong);

    if (samePasswords && newEmail && isPasswordStrong) {
      try {
          const response = await fetch(`${BACKEND_URL}/auth/signup`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  email: inputs.email,
                  password: inputs.password,
                  firstName: inputs.firstName, // Include first name
                  lastName: inputs.lastName,   // Include last name
              }),
          });

          const data = await response.json();
          console.log(data.userId);
          
          if (response.status === 200) {
              alert('Successfully signed up!')
              console.log(data.message);  // User registered
              if (data.users) {
                console.log('Users Database:', data.users);
                setIsLoggedIn(true); // Set login status
                setUserName(inputs.email); // Set userName to the email
                setUserId(data.userId);
                setIsAdmin(false);
            }
            navigate(`/`)
          } else if (response.status === 400) {
              alert('Email already in use.')
              handleEmailAlreadyExists(true);
              console.log(data.message);  // Email already in use
          } else {
              alert('There was an error registering the user.')
              console.log('Unknown error occurred');
          }
      } catch (error) {
          console.error('There was an error registering the user:', error);
      }
    }
    console.log(inputs);
  }

  return (
    <form className='user-form' onSubmit={handleSubmit}>
      <label className='input-container'>
        <input className='form-input'
          type='text' 
          name='firstName' 
          placeholder='First Name'
          value={inputs.firstName || ''} 
          onChange={handleChange}
        />
        <div className='img-container'>
          <img className='icon' src={userIcon} alt='first name icon'/>
        </div>
      </label>
      <label className='input-container'>
        <input className='form-input'
          type='text' 
          name='lastName' 
          placeholder='Last Name'
          value={inputs.lastName || ''} 
          onChange={handleChange}
        />
        <div className='img-container'>
          <img className='icon' src={userIcon} alt='last name icon'/>
        </div>
      </label>
      <label className='input-container'>
        <input className='form-input'
          type='text' 
          name='email' 
          placeholder='Email'
          value={inputs.email || ''} 
          onChange={handleChange}
        />
        <div className='img-container'>
          <img className='icon' src={userIcon} alt='email icon'/>
        </div>
      </label>
      <label className='input-container'>
        <input className='form-input'
          type='password' 
          name='password' 
          placeholder='Password'
          value={inputs.password || ''} 
          onChange={handleChange}
        />
        <div className='img-container'>
          <img className='icon' src={ passwordIcon } alt='password icon'/>
        </div>
      </label>
      <label className='input-container'>
        <input className='form-input'
          type='password' 
          name='passwordConfirmation' 
          placeholder='Confirm Password'
          value={inputs.passwordConfirmation || ''} 
          onChange={handleChange}
        />
        <div className='img-container'>
          <img className='icon' src={ passwordIcon } alt='password icon'/>
        </div>
      </label>
      <input className='submit-user-button' type='submit' value='SIGNUP'/>
    </form>
  )
}