/**
    Create a React component representing the team name
    for each team on the About Us page.
    The style changes depending on whether the team section
    contains team cards or just a list of names.

    @author Sergio Perez
*/

export default function TeamBanner({ teamName, list }) {
    const bannerClasses = `team-banner-container ${list ? 'team-list-banner' : ''}`;

    return (
        <div className={bannerClasses}>
            <div className="team-banner-text">{teamName}</div>
        </div>
    );
}