import "./eventsPagination.css"

export default function EventsPagination({ showcaseSize, numOfEvents, showcaseSetter, currentShowcase }) {

    const numPages = Math.ceil(numOfEvents / showcaseSize);

    const handlePageButtonClick = (e) => {
        const page = e.target.textContent;
        showcaseSetter(Number(page));
    }

    const handlePreviousButtonClick = () => {
        if (currentShowcase > 1) {
            showcaseSetter(currentShowcase - 1);
        }
    }

    const handleNextButtonClick = () => {
        if (currentShowcase < numPages) {
            showcaseSetter(currentShowcase + 1);
        }
    }

    const handleSkipStartButtonClick = () => {
        showcaseSetter(1);
    }

    const handleSkipEndButtonClick = () => {
        showcaseSetter(Number(numPages));
    }

    const handleActiveButtonClick = (e) => {
        e.preventDefault()
    }

    let pageButtons = [];

    for (let i = 0; i < numPages; i++) {
        const currentPage = i === currentShowcase - 1
        const className = currentPage ? (
            "events-pagination-button events-pagination-page-button events-pagination-page-button-active"
        ) : (
            "events-pagination-button events-pagination-page-button"
        );

        pageButtons.push( 
            !currentPage ? (
                <button className={className}
                    onClick={handlePageButtonClick}
                    >
                    {i + 1}
                </button>
            ) : (
                <button className={className}
                    onClick={handleActiveButtonClick}
                    >
                    {i + 1}
                </button>
            )
        );
    }

    return (
        <div className="events-pagination-container">
            <button className="events-pagination-button"
                onClick={handleSkipStartButtonClick}
                >
                {`<<`}
            </button>

            <button className="events-pagination-button"
                onClick={handlePreviousButtonClick}>
                {`<`}
            </button>

            {pageButtons}

            <button className="events-pagination-button"
                onClick={handleNextButtonClick}>
                {`>`}
            </button>

            <button className="events-pagination-button"
                onClick={handleSkipEndButtonClick}
                >
                {`>>`}
            </button>
        </div>
    );
}