/** 
    Create a React component representing the summary report
    generated from the WISEST tool. This is the component
    that will be included in the results export.
    This includes charts and graphics that summarizes the 
    WISEST tool's appraisal.
    The component is designed to specifically fit on A4 paper.

     @author Cassandra Tin Kwon Yuen
*/

import React from "react";
import "./ExportResults.css";
import { HiUser } from 'react-icons/hi2'
import { IoHandLeft } from 'react-icons/io5'
import { RiEqualizerFill } from 'react-icons/ri'
import { BsClipboard2CheckFill } from 'react-icons/bs'



const getClassName = (key) => {
    if (key.includes("Number of primary")) {
      return "grey-box-export"
    } else if (key.includes("ROBIS domain")) {
      return "yellow-box-export"
    } else if (key.includes("Overall ROBIS")) {
      return "light-teal-box-export"
    } else if (key.includes("ROBIS Overall")) {
      return "teal-box-export"
    } else if (key.includes("AMSTAR")) {
      return "purple-box-export"
    }
    return ""
  };
  
  // Circle colour depending on the heading
  const getCircleColour = (key) => {
    if (key.includes("Participants")) {
      return "#f49c5aff"
    } else if (key.includes("Interventions")) {
      return "#72bda9ff"
    } else if (key.includes("Comparators")) {
      return "#7986bbff"
    } else if (key.includes("Outcomes")) {
      return "#262a35ff"
    }
    return ""
  };
  
  // Icon source depending on the heading
  const getIcon = (key) => {
    if (key.includes("Participants")) {
      return <HiUser />
    } else if (key.includes("Interventions")) {
      return <IoHandLeft />
    } else if (key.includes("Comparators")) {
      return <RiEqualizerFill />
    } else if (key.includes("Outcomes")) {
      return <BsClipboard2CheckFill />
    }
    return <></>
  };

  const insertLineBreaks = (text) => {
    const modifiedText = text.replace('/', '/\n');
    return <div dangerouslySetInnerHTML={{ __html: modifiedText }} />;
  };

  const styles = {
    position: 'relative',
    zIndex: 999,
  };

const SummaryPage = (({ summary }) => {
    return (
      <div id="summary-export">
        <div className="report-title-export export-text">WISEST Summary Report</div>
        {/* TABLE1 */}
        <div className="grid1-export">
          {/* Top heading */}
          <div className="table1-header-export">
            <div className="export-text">{summary.table1[0]}</div>
          </div>
          {summary.table1[1].map((item, index) => (
            <div className="table1-row-export">
              {/* Left hand side headings */}
              <div className="table1-left-export" key={index}>
                <div className="export-text">{item[0]}</div>
              </div>
              {/* Right hand side values */}
              <div className="table1-right-export" key={index}>
                <div className="export-text">{item[1]}</div>
              </div>
            </div>
          ))}
  
        </div>
  
        {/* TABLE2 */}
        
        <div id="quality-export">
        <div className="report-subheading-export export-text"> Quality Assessment Results</div>
        <div id="quality-container-export">
          {summary.table2.map((item, index) => (
            <div key={index} className={`grid2-export ${getClassName(item[0])}`}>
              {/* Left hand side bolded heading */}
              <div className="table2-left-export">
                <p className="table2-text-export export-text">{item[0]}</p>
              </div>
  
              {Array.isArray(item[1]) ? (
                // If the value is an array (ROBIS domain judgments or Overall ROBIS judgments)
                <div className={"table2-row"}>
                  {item[1].map((subItem, subIndex) => (
                    <div key={subIndex} className={"table2-subrow"}>
                      {/* Middle column */}
                      <div className="table2-middle-export">
                        <p className="table2-text-export export-text">{subItem[0]}</p></div>
                      {/* Right column value */}
                      <div className="table2-right-export">
                        <p className="table2-text-export export-text">{subItem[1]}</p></div>
                    </div>
                  ))}
                </div>
              ) : (
                // If the value is not an array (right column value)
                <div className="table2-right-export">
                  <p className="table2-text-export export-text">{item[1]}</p>
                </div>
              )}
            </div>
          ))}
          </div>
        </div>
  
        {/* TABLE3 */}
        <div id="icon-grid-container-export">
        <div className="icon-grid-export">
          {summary.table3.map((item, index) => (
            <div className="icon-container-export">
              {/* Conditionally change icon depending on the heading (item[0])*/}
              <div className="circle-export" style={{ backgroundColor: getCircleColour(item[0]) }}>
                <div className="report-icon-export">{getIcon(item[0])}</div>
              </div>
              {/* Headings */}
              <div key={index} className="icon-heading-export export-text">{insertLineBreaks(item[0])}</div>
              {/* Values */}
              <div className="icon-text-export export-text">{item[1]}</div>
            </div>
          ))}
        </div>
        </div>
        {/* CONCLUSION */}
        <div className="conclusions-export">
          <div className="report-subheading-export export-text">Conclusions</div>
          <div className="conclusion-text-export export-text">{summary.conclusion}</div>
        </div>
      </div>
    )
  });

  export default SummaryPage
