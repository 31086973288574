/** 
    Create a React component representing the swap button
    for switching between the uploads and report pages.
    Used for tablet/mobile versions.

     @author Sergio Perez
*/

export default function SwapButton({ onClick, text }) {

    return (
        <button className="results-header-swap-button"
            onClick={onClick}
        >
            {text}
        </button>
    );
}