/**
 *  This component represents the Loading page. Users are redirected here while the 
 *  backend is processing a post request. When the processing ends, users
 *  are redirected to the results page.
 * 
 *  @author  Alessandro Marmi, Aliyah James
 */


import "animate.css"; // import animations for the text on the loading page 
import "./Wisest.css";
import wisestLogo from "../../images/logo.png" // import logo 
import SyncLoader from "react-spinners/SyncLoader"; 



// react spinner
const Loader = () => {
  return (    
      <SyncLoader
      color="#c8e6dfff"
      margin={20}
      size={25}
      speedMultiplier={0.60}
      />     
);
}

const LoadingPage = () => {
  var headerColour = getComputedStyle(document.body).getPropertyValue('white');
  document.body.style.backgroundColor = headerColour;
  return (
    <div className="logo-container-loading">
        <img id="loading-logo" src={ wisestLogo } alt='Wisest logo'></img>
      <div className="loading-container-text">
        {/* animate the txt on the loading page   using react animate.css library*/}
        <h3 className="animate__animated  animate__bounceInLeft animate__repeat-4 animate__delay-0.5s ">Please wait while we load your results</h3>  
      </div> 
      <div className="loader-container">
        <Loader /> 
      </div>  
    </div>   
   
);
}

export default LoadingPage;