import { useState } from "react";
import { IoSearch } from "react-icons/io5";

export default function SearchBar({ searchFilterSetter }) {
    const [searchTerm, setSearchTerm] = useState("");

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        searchFilterSetter(searchTerm);
    }

    return (
        <form className="events-search-bar-form" onSubmit={handleSubmit}>
            <label className="events-search-bar-input-container">
                <IoSearch className="events-search-bar-icon"/>
                <input className="events-search-bar-input"
                    type="text"  
                    placeholder="Search for events..."
                    value={searchTerm}
                    onChange={handleInputChange}
                />
            </label>
            <label className="events-search-bar-button-container">
                <button className="events-search-bar-button" type="submit">
                    SEARCH
                </button>
            </label>
        </form>
    );
}