/**
 * Create the React component for the pie graph on the admin dashboard
 * page representing the percentage of users that reported
 * encountering problems when using the WISEST tool.
 * 
 * @author Elena Scobici
 */

import React from "react";
import { Pie, PieChart, LabelList, Legend, Tooltip } from "recharts";

const ProblemRatePieGraph = ({problems, no_problems, size}) => {
    if (size >= 1200) {
        size = 500;
    }
    
    const problem_percent = Math.round(problems / (problems + no_problems) * 100);

    const data = [{name:"Problems", value: problems, valuePercent: String(problem_percent) + "%", fill:"#d1d186"},
        {name:"No Problems", value: no_problems, valuePercent:String(100 - problem_percent) + "%", fill:"var(--teal)"}];

    return (
        <PieChart width={size} height={250}>
            <Tooltip />
            <Legend layout="vetical" verticalAlign="bottom" align="right" />
            <Pie data={data} dataKey="value" nameKey="name" cx="50%" 
                cy="50%" outerRadius={100}>
                <LabelList dataKey="valuePercent" position="outside" stroke={""} fontWeight={"bold"} fontSize={20}/>
            </Pie>
        </PieChart>
    )
}

export default ProblemRatePieGraph;