/**
   Styled components for the navigation bar.

    @author Alessandro Marmi, Cassandra Tin Kwon Yuen
*/

import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import {FaBars} from "react-icons/fa";



export const Sidebar = styled.div`
    position: absolute;
    top: 85px;
    right: -300px;  // Starts hidden on the right
    width: 250px;   // width of the sidebar
    height: 100vh;  
    background-color: white;
    z-index: 999;  // To ensure it appears on top
    

    a {
        display: block;
        padding: 10px 15px;
        color: var(--black);
        text-decoration: none;
        font-size: 18px; 
        &:hover {
            background-color: var(--grey);
        }
    }

    ${({ sidebarIsOpen }) => sidebarIsOpen && `
        right: 0;
    `}
`;


export const Nav = styled.nav`
  background: transparent;
  height: 80px;
  display: flex;
  // justify-self: center;
  justify-content: center;
  padding: 0.5rem calc((100vw - 1000px) / 10); // padding changes as page size changes
  z-index: 10;
  padding-left: 0;
  margin-left: 0;

  @media screen and (max-width: 1000px) { 
    height: 0;
    padding: 0;
  }
`;

// Header row containing the logo
export const NavLogo = styled.nav`
  background: transparent;  // change this to 
  height: 70px;
  display: flex;
  // justify-content: space-between;
  padding: 0.5rem calc((100vw - 1000px) / 10); // padding changes as page size changes
  z-index: 10;

`;

export const LogoLink = styled(Link)`
   display: flex;
   justify-content: center;
   align-items: flex-start;
   cursor: pointer;
   // Fix position to top
   position: absolute; 
   top: 20px;
   left: 50%;
   transform: translateX(-50%);

  @media screen and (max-width: 1000px) { // align logo left when menu appears
    left: 110px;
    top: 10px;
  }

  @media screen and (max-width: 500px) { // align logo left when menu appears
    left: 100px;
  }

`;

  // all links except the sign in and the logo
export const StyledNavLink = styled(Link)`
   color: var(--black);
   font-size: 20px;
   display: flex;
   align-items: center;
   text-decoration: none;  // removes underscore 
   height: 100%;
   cursor: pointer;
   margin-right: calc(6vw); // This adds a margin that grows/shrinks with the viewport width.
   &:last-child {
      margin-right: 0;  // Ensure that the last link doesn't have a right margin.
   }
   &:hover {
    transition: all 0.2s ease-in-out;
    color: var(--purple); 
   }
   &.active {
    text-decoration: underline solid #EBDF95 3px;
    text-underline-offset: 5px;
    color: var(--purple);
    font-weight: 600;
   }
`;

export const Bars = styled(FaBars)`  // this is the icon of the sidebar. 
  display: none;
  color: var(--black); // icon is now same color as text

  @media screen and (max-width: 1000px) { // appears when screen size < 1000px
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
  // margin-left: 300px; // to not collapse on logo
  @media screen and (max-width: 1000px) { // disappears when screen size < 1000px
    display: none;
  }
`;


// sign up and log in button container
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1000px) {  // disappears when screen size < 1000px
    display: none;
  }
`;

// log in and sign up links
export const NavBtnLink = styled(Link)`
  border-radius: 4px;  // makes it rounded at edges
  padding: 10px 22px;
  color: var(--teal);   // white text inside
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;  // makes transition when you hover
  text-decoration: none;

  position: absolute;
  right: 0;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: none;
    color: var(--dark-teal);
  }

  @media screen and (max-width: 1000px) { 
    text-align: left;
    position: relative;
    left: 0;
    color: var(--teal) !important;
  }
`;
