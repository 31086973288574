/**
 * React component representing an uploaded file within the
 * WisestTool.js component. The fileName is a string representing the
 * name of the uploaded file, including its file extension. 
 * 
 * Displays the name of the file, an icon representing its file type
 * and a checkmark icon to indicate that the file has been uploaded
 * successfully.
 * 
 * @author Elena Scobici
 */

import "./Wisest.css";
import React from 'react';
import {IoCheckmarkCircle} from 'react-icons/io5'
import {FileIcon, FileNameText, UploadedFileContainer} from './WisestElements'
import pdfIcon from '../../images/pdf.png'
import txtIcon from '../../images/txt.png'
import texIcon from '../../images/tex.png'

const UploadedFileContainerComponent = ({fileName}) => {
    return(
        <UploadedFileContainer>
            {fileName.substr(fileName.length - 3) === 'pdf' && <FileIcon src={pdfIcon}></FileIcon>}
            {fileName.substr(fileName.length - 3) === 'txt' && <FileIcon src={txtIcon}></FileIcon>}
            {fileName.substr(fileName.length - 3) === 'tex' && <FileIcon src={texIcon}></FileIcon>}
            <FileNameText>{fileName}</FileNameText>
            <IoCheckmarkCircle size={40} color='var(--yellow)'></IoCheckmarkCircle>
        </UploadedFileContainer>
    )
}

export default UploadedFileContainerComponent;