/**
    Create a React component representing a text input
    field for the About Us page feedback form.
    There is a maximum character limit of 255 characters.
    The user will be able to see their character count,
    and will not be permitted to type any more than
    the maximum.


    @author Cassandra Tin Kwon Yuen
*/

import React from 'react';
import CharacterCounter from './CharacterCounter';
import { FEEDBACK_CHAR_LIMIT } from '../../../utils/constants';

const TextInput = ({ label, name, value='', handleChange }) => {
  const isLimitExceeded = value.length > FEEDBACK_CHAR_LIMIT;

  return (
    <div className="feedback-input">
      <label className="feedback-text">
        {label}
      </label>
      <div  className="feedback-textbox">
        <textarea
          rows="4"
          className="feedback-text"
          name={name}
          value={value || ''}
          onChange={handleChange}
          maxLength={FEEDBACK_CHAR_LIMIT}
        />
        <CharacterCounter text={value} />
      </div>
    </div>
  );
};

export default TextInput;
