/**
    Create a React component representing the About Us Page,
    with sections for "Meet the Team", "Contact Us", and
    "Leave Us Some Feedback".

    @author Cassandra Tin Kwon Yuen, Sergio Perez
*/


import Banner from './Banner';
import FeedbackForm from './Feedback/FeedbackForm';
import './aboutUsStyle.css';

export default function FeedbackPage() {
    var headerColour = getComputedStyle(document.body).getPropertyValue('--light-grey'); 
    document.body.style.backgroundColor = headerColour;
    
    return (
        <div className="about-us-container" >
            <Banner text="Feedback" />
            <div className="about-us-item">
                <div className="about-us-subtitle feedback-container">
                    LEAVE US SOME FEEDBACK
                </div>
                <FeedbackForm />
            </div>
            
        </div>
    )
}