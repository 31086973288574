/**
   Create a react component representing a grid
   on the home page. 

    @author Cassandra Tin Kwon Yuen
*/


import React from 'react';
import './Home.css'

const HomeGridRow = ({icon, iconName, subtitle, text}) => {
    return (
        <>
            <div className="grid-item">
                <div className="blue-circle">
                    <img className="issue-icon" src={icon} alt={iconName}></img>
                </div>
            </div>
            <div className="grid-item">
                <h3 className="body-text bold-text">{subtitle}</h3>
                <h4 className="body-text small-text">{text}</h4>
            </div>
        </>
    )
}

export default HomeGridRow;