import ResetPasswordForm from './ResetPasswordForm';
import ErrorBanner from '../ErrorElements/ErrorBanner';
import NavigationButton from '../MiscElements/NavigationButton';
import Background from '../MiscElements/Background';
import { useState } from 'react';
import logo from '../../../images/logo.svg';
import '../generalStyle.css';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

export default function SetNewPassword() {
    const [ diffPasswords, setDiffPasswords ] = useState(false);
    const [wrongEmail, setWrongEmail] = useState(false);
    const [emptyFields, setEmptyFields] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);

    function handleDiffPasswords(newValue) {
        setDiffPasswords(newValue);
    }

    function handleWrongEmail(newValue) {
        setWrongEmail(newValue);
    }

    function handleEmptyEmail(newValue) {
        setEmptyEmail(newValue);
    }

    function handleEmptyFields(newValue) {
        setEmptyFields(newValue);
    }

    return (
        <> 
            <div id='general'>
            <Background />
            <Link to="/" className='home-icon'>
                <FaHome url='/'/>
            </Link>
            <div className='container'>
                <img className='wisest-logo' src= {logo} alt='Wisest logo' />
                <ErrorBanner isActive={ diffPasswords } text="Uh-oh! Your passwords don't match!" />
                <ErrorBanner isActive={ wrongEmail } text='Uh-oh! This email is not registered!' />
                <ErrorBanner isActive={ emptyFields } text='Uh-oh! Please fill in all the fields!' />
                <ErrorBanner isActive={ emptyEmail } text='Uh-oh! Your email is empty!' />
                <ResetPasswordForm 
                    handleDiffPasswords={ handleDiffPasswords } 
                    handleWrongEmail={ handleWrongEmail }
                    handleEmptyFields={ handleEmptyFields }
                    handleEmptyEmail={ handleEmptyEmail }
                />
            </div>
            </div>
        </>
    )
}