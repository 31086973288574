/**
    Create a React component representing the lesson
    content in the training page.
    This content is located inside dropdowns that must
    be opened in order to reveal the text.
    The actual content comes from utils/lessons.js, which contains
    the html for each lesson. 
    Inside this dropdown is the "Mark as (in)complete" button that
    will toggle the completion status and trigger the POST request
    if the user is logged in.

    @author Sergio Perez
*/

import "./lessonCardDropdown.css"

export default function LessonCardDropdown({ 
    content, isActive, isCompleted, completedToggler
}) {
    
    let dropdown = isActive ? (
        <>
            <div className="lesson-card-dropdown-container">
                <p>{content}</p>
           

            <button className="lesson-card-complete-button" 
                onClick={completedToggler}
            >
                {isCompleted ? "Mark as incomplete" : "Mark as complete"}
            </button>
            </div>
        </>
    ) : []
    return dropdown
}