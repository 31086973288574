/**
    Create a React component representing the About Us Page,
    with sections for "Meet the Team", "Contact Us", and
    "Leave Us Some Feedback".

    @author Cassandra Tin Kwon Yuen, Sergio Perez
*/


import Banner from './Banner';
import Teams from './TeamMembers/Teams';
import ContactInfo from './ContactUs/ContactInfo';
import './aboutUsStyle.css';

export default function AboutUs() {
    var headerColour = getComputedStyle(document.body).getPropertyValue('--light-grey'); 
    document.body.style.backgroundColor = headerColour;
    
    return (
        <div className="about-us-container" >
            <Banner text="About Us" />
            <div className="about-us-item" >
                <div className="about-us-subtitle">
                    MEET THE TEAM
                </div>
                <Teams />
            </div>
            <div className="about-us-item contact-container">
                <div className="about-us-subtitle contact-us">CONTACT US</div>
                <ContactInfo />
            </div>
        </div>
    )
}