import { getFormattedDate } from "../eventCardFunctions";

export default function EventContent({ title, description }) {
    return (
        (description.length > 56 ? 
            <>
            <div className="event-card-content-container">
                <h2 className="event-card-content-date">{title}</h2>           
                <p className="event-card-scrollable">{description}</p>
            </div>
            </>
             : 
             <>
             <div className="event-card-content-container">
                <h2 className="event-card-content-date">{title}</h2>           
                <p className="event-card-content-description" >{description}</p>
            </div>
             </>         
             )
    )
}