import React, { useState, useEffect } from 'react';
import { BACKEND_URL } from '../../utils/constants';

const AdminGroupModal = ({ isOpen, onClose, token }) => {
    const [adminUsers, setAdminUsers] = useState([]);
    const [newAdminEmail, setNewAdminEmail] = useState('');

    useEffect(() => {
        if (isOpen) {
            fetchAdminUsers();
        }
    }, [isOpen]);

    const fetchAdminUsers = async () => {
        try {
            const response = await fetch(`${BACKEND_URL}/get-admins`, {
                headers: { "Authorization": `Bearer ${token}` }
            });
            const data = await response.json();
            setAdminUsers(data);
        } catch (error) {
            console.error('Failed to fetch admin users:', error);
        }
    };

    const handleAddAdmin = async () => {
        const response = await fetch(`${BACKEND_URL}/make-admin`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: newAdminEmail })
        });
        if (response.ok) {
            fetchAdminUsers(); // Refresh the list of admin users
            setNewAdminEmail(''); // Clear the input
        }
    };

    const handleRemoveAdmin = async (email) => {
        const response = await fetch(`${BACKEND_URL}/remove-admin`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });
        if (response.ok) {
            fetchAdminUsers(); // Refresh the list after removal
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Manage Admin Users</h2>
                <div className="modal-content_header">
                    <input
                        type="email"
                        placeholder="Enter new admin email"
                        value={newAdminEmail}
                        onChange={(e) => setNewAdminEmail(e.target.value)}
                    />
                    <button className='btn-primary' onClick={handleAddAdmin}>Add Admin</button>
                </div>
                
                <table>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {adminUsers.map((user, index) => (
                            <tr key={index}>
                                <td>{user.email}</td>
                                <td><button className='btn-danger' onClick={() => handleRemoveAdmin(user.email)}>Remove</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminGroupModal;