/**
 * Create the React component for the picture graph on the admin
 * dashboard page representing the percentages of users that gave the
 * WISEST tool each star rating from 1 to 5.
 * 
 * @author Elena Scobici
 */

import React from "react";
import StarIcon from "../../images/star.png"
import BigStar from "../../images/big-star.png"
import './AdminDashboard.css';

const OverallRatingGraph = ({stats}) => {
    const tempStatsMap = new Map(Object.entries(stats));
    const statsMap = new Map();

    // Divide all keys by 2 to convert 10-point rating to 5-point
    // rating.
    for (const [key, value] of tempStatsMap) {
        const updatedKey = String(Math.floor(key / 2)); // Divide the key by 2
        statsMap.set(updatedKey, value);
    }
    
    const RenderStars = (starCount) => {
        const stars = [];
        for (let i = 0; i < starCount; i++) {
            stars.push(<img class="star-icon" src={StarIcon}></img>)
        }
        return stars;
    }

    const getCount = (ratingString) =>  {
        return statsMap.get(ratingString) ? statsMap.get(ratingString) : 0;
    }

    const totalCount = [1, 2, 3, 4, 5].reduce((partialSum, i) => partialSum + getCount(String(i)), 0);

    const getPercent = (ratingString) => {
        return String(totalCount === 0 ? 0 : 
            parseInt(getCount(ratingString))/totalCount * 100) + "%";
    }

    const averageRating = totalCount === 0 ? "0.0" : 
        ([1, 2, 3, 4, 5].reduce((partialSum, i) => 
        partialSum + getCount(String(i)) * i, 0) / totalCount)
        .toFixed(1);

    return (
        <div class="overall-rating-container">
            <div class="rating-info-container">
                <div class="star-cell">
                    {RenderStars(5)}
                </div>
                <p class="rating-text">{getPercent("5")}</p>
                <p class="rating-text">{getCount("5")}</p>
                <div class="star-cell">
                    {RenderStars(4)}
                </div>
                <p class="rating-text">{getPercent("4")}</p>
                <p class="rating-text">{getCount("4")}</p>
                <div class="star-cell">
                    {RenderStars(3)}
                </div>
                <p class="rating-text">{getPercent("3")}</p>
                <p class="rating-text">{getCount("3")}</p>
                <div class="star-cell">
                    {RenderStars(2)}
                </div>
                <p class="rating-text">{getPercent("2")}</p>
                <p class="rating-text">{getCount("2")}</p>
                <div class="star-cell">
                    {RenderStars(1)}
                </div>
                <p class="rating-text">{getPercent("1")}</p>
                <p class="rating-text">{getCount("1")}</p>
            </div>
            <div class="average-rating-container">
                <div class="star-container">
                    <p id="average-rating-text">{averageRating}</p>
                    <img id="big-star" src={BigStar}></img>
                </div>
                <p class="average-rating-label">Average</p>
                <p class="average-rating-label">Rating</p>
            </div>
        </div>
    )
}

export default OverallRatingGraph;