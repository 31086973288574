/**
 *  This component represents the Wisest Tool page. Here users can upload their files 
 *  (accpted types: .txt, .tex, .pdf) and upload them to get them appraised by the AI.
 *  This component is connected to a Flask python backend that returns the AI's results.
 *  
 *  Note for TA:
 *      Right now the AI has not been provided to us by the client. We are using a fixed sample result 
 *      and our python backend is returning it regardless of the file being uploaded.
 * 
 * 
 *  @author Alessandro Marmi, Aliyah James, Elena Scobici, Cassandra Tin Kwon Yuen
 */


import "./Wisest.css";
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoIosCloudUpload } from 'react-icons/io'
import {
  ToolBoxContainer, ToolBox, LogoContainer, SubHeading, DragDrop,
  DragDropMessage, UploadContainer, SubmitContainer,
  EmptyDiv
} from './WisestElements'
import UploadedFileContainerComponent from "./UploadedFileContainer";
import { AuthContext } from "../AuthenticationPages/AuthContext";
import { BACKEND_URL } from "../../utils/constants" ;

const WisestTool = () => {
  // Boolean variable that keeps track of whether the screem is in
  // desktop view (>= 1000px width, true) or mobile view (false).
  let currentlyLargeScreen = window.innerWidth >= 1000;

  const [fileUploaded, setFileUploaded] = useState(false); // keeps track of whether a file is uploaded
  const [File, setFile] = useState(''); // stores the file
  const { isLoggedIn, userName, token } = useContext(AuthContext);

  // Keep track of the text on the drag & drop component
  const desktopBrowseText = 'Drag & drop files or click to browse';
  const mobileBrowseText = 'Browse files';
  const [browseText, setBrowseText] = useState(currentlyLargeScreen ? desktopBrowseText : mobileBrowseText);


  // list of names of uploaded files
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleUploadBoxClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();  // allows to drag elements
  };

  useEffect(() => {
    if (isLoggedIn) {
      const fetchUploadedFiles = async () => {
        try {
          const encodedUserName = encodeURIComponent(userName);
          const url = `${BACKEND_URL}/user-uploads?userName=${encodedUserName}`;
          const response = await fetch(url, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
              }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          const fileNames = data.map(upload => upload.file_name); 
          setUploadedFiles(fileNames);
        } catch (error) {
          console.error('Error fetching user uploads:', error);
        }
      };

      fetchUploadedFiles();
    }
  }, [isLoggedIn, userName]);


    const handleDrop = (e) => {
      e.preventDefault();
      let files = e.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];
        const fileName = file.name;
        const fileType = fileName.split('.').pop().toLowerCase();
  
        if (uploadedFiles.includes(fileName)) {
          alert('File already uploaded.');
          return;
        }
        
        if (fileType === 'pdf' || fileType === 'tex' || fileType === 'txt') {
          setFileUploaded(file);  // Update the fileUploaded state
          alert('Success!')
        } else {
          alert('Invalid file type. Please upload .pdf, .tex, or .txt files.');
        }
      }
    };
  
  const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        const fileName = file.name;
        const fileType = fileName.split('.').pop().toLowerCase();
  
        if (uploadedFiles.includes(fileName)) {
          alert('File already uploaded.');
          return;
        }
  
        if (['pdf', 'txt', 'tex'].includes(fileType)) {
          setFileUploaded(file); //to display the name of the file when uploaded 
          alert('Success!')
        } else {
          alert('Invalid file type. Please upload .pdf, .tex, or .txt files only.');
        }
      }
    };
  
  const SubmitFileButton = () => {
    const navigate = useNavigate();

    const handleClick = async () => {

      if (!fileUploaded) {
        alert('Upload a valid file first!');
        return;
      }

      if (!isLoggedIn) {
        alert('You are not Logged In!');
        return;
      }
      // Redirect to loading page
      navigate('/loading');

      const formData = new FormData();
      formData.append('file', fileUploaded)
      formData.append('email', userName)
      const response = await axios.post(`${BACKEND_URL}/upload`, formData, {
          headers: {
              Authorization: `Bearer ${token}`, // Replace `token` with your actual token variable
              'Content-Type': 'multipart/form-data', // This is usually required for file uploads
          },
      });

      const result = response.data;

      // Once the value is returned, navigate to another page or update state
      navigate('/Results', { state: { result, fileUploaded } });
    };

    return <button onClick={handleClick} className="submit-button"> Submit </button>;
  };

  // Switch the screen view mode.
  function handleResize() {
    if (window.innerWidth < 1000 === currentlyLargeScreen) {
      currentlyLargeScreen = !currentlyLargeScreen;
      setBrowseText(currentlyLargeScreen ? desktopBrowseText : mobileBrowseText);
    }
  }

  // Listen for window resize.
  window.addEventListener('resize', handleResize);

  return (
    <>
      <ToolBoxContainer>
        <ToolBox>
          <LogoContainer>Upload Files to WISEST</LogoContainer>
          <SubHeading>(pdf, txt or tex)</SubHeading>
          <UploadContainer>
            <DragDrop onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleUploadBoxClick}>
              {(!fileUploaded || currentlyLargeScreen) &&
                <IoIosCloudUpload size={80} color='var(--middle-purple)' />
              }
              {fileUploaded && !currentlyLargeScreen &&
                <UploadedFileContainerComponent fileName={fileUploaded.name}></UploadedFileContainerComponent>
              }
              <DragDropMessage>{browseText}</DragDropMessage>
            </DragDrop>
            <SubmitContainer>
              <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileUpload} data-testid="file-input" />
              {fileUploaded && currentlyLargeScreen && (
                <UploadedFileContainerComponent fileName={fileUploaded.name}></UploadedFileContainerComponent>
              )}
              {/* Empty tag to ensure that the submit button is 
                always at the bottom of the screen. */}
              <EmptyDiv></EmptyDiv>
              <SubmitFileButton />
            </SubmitContainer>
          </UploadContainer>
        </ToolBox>
      </ToolBoxContainer>
    </>
  );
}
export default WisestTool;
