
export default function ErrorBanner({ isActive, text }) {
    let banner = [];

    if (isActive) {
        banner = <div className='wrong-credentials'>
            <p className='error-text'>{ text }</p>
        </div>
    }
    return (banner)
}