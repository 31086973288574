/**
    Create a React component representing each lesson, 
    including the lesson header with the completion status 
    and dropdown toggle, and the actual lesson content.
    The GET and POST requests are handled here. 
    We retrieve the user's training progress and mark the completion 
    statuses of each lesson accordingly. If they are not signed in
    or have never updated any progress, the default will have
    all the statuses as incomplete.
    When the user presses "Mark as complete" or "Mark as incomplete",
    the status will toggle accordingly and the update for that
    lesson will be sent to the database if the user is logged in.
    If they are not logged in, no progress will be saved, and they
    will be alerted of this the first time they toggle one of the buttons.

    @author Cassandra Tin Kwon Yuen, Sergio Perez
*/

import { useState, useEffect, useContext } from "react";
import LessonCardHeader from "./CardElements/LessonCardHeader";
import LessonCardDropdown from "./CardElements/LessonCardDropdown";
import { AuthContext } from "../../../../AuthenticationPages/AuthContext";
import { BACKEND_URL } from "../../../../../utils/constants";

let alertShown = false;

export default function LessonCard({ props, completedProgress }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const { userId } = useContext(AuthContext);
    const type = props.type;
    const number = props.number;
    const header = props.header;
    const content = props.content;
    const { token } = useContext(AuthContext);

    // Get the training progress of current user (if logged in)
    useEffect(() => {
        if (userId && completedProgress) {
          const prefix = number > 5 ? 'optional' : 'lesson';
          const lessonNum = number > 5 ? number - 5 : number;
          const completionState = completedProgress[`${prefix}${lessonNum}`];
          setIsCompleted(completionState);
        } else {
          // Set everything to false if the user is not logged in or completedProgress is not available
          setIsCompleted(false);
        }
      }, [userId, number, completedProgress]);

      // Send post request to update progress every time a button is clicked
      const handleToggleCompletion = async () => {
        try {
            if (!userId) {
                if (!alertShown) {
                    // Only alert the user if they are not logged in and on the first time
                    // they toggle a button
                    alert('Warning: Your progress will not be saved unless you are logged in');
                    alertShown = true;
                }
                
                setIsCompleted((prevIsCompleted) => !prevIsCompleted);
                return;
            }     

            const updatedIsCompleted = !isCompleted;
            setIsCompleted(updatedIsCompleted);
          const response = await fetch(`${BACKEND_URL}/update-training`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              userId: userId,
              lessonNum: props.number,
              complete: updatedIsCompleted,
            }),
          });
    
          const data = await response.json();          
    
          if (response.ok) {
            console.log('Progress updated successfully!');
            setIsCompleted(updatedIsCompleted);
          } else {
            console.error('Failed to update progress');
          }

          console.log('isCompleted before toggle:', updatedIsCompleted);
          console.log('isCompleted after toggle:', isCompleted);
        } catch (error) {
          console.error('Error updating lesson completion status:', error);
        }
      };

    return (
        <>
            <LessonCardHeader number={number} 
                header={header} 
                dropdownToggler={setIsExpanded} 
                dropdownOpen={isExpanded}
                isCompleted={isCompleted}
                type={type}
                />
            <LessonCardDropdown content={content} 
                isActive={isExpanded}
                isCompleted={isCompleted}
                completedToggler={handleToggleCompletion}
            />
        </>
    );
}