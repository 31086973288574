import LogInForm from './LogInForm';
import NavigationButton from '../MiscElements/NavigationButton';
import ErrorBanner from '../ErrorElements/ErrorBanner';
import Background from '../MiscElements/Background';
import { useState } from 'react';
import logo from "../../../images/logo.png";
import '../generalStyle.css';
import { FaHome } from "react-icons/fa";
import { Link } from 'react-router-dom'; // Import Link component


export default function LogIn() {
    const [ wrongCredentials, setWrongCredentials ] = useState(false);

    function handleWrongCredentials() {
        console.log('Wrong credentials');
        setWrongCredentials(true);
    }

    return (
        <>
            <div id='general'>
            <Background />
            <Link to="/" className='home-icon'>
                <FaHome url='/'/>
            </Link>
            <div className='container'>
                <img className='wisest-logo' src={ logo } alt='Wisest logo' />
                <ErrorBanner isActive={ wrongCredentials } text='Uh-oh! Your email or password is incorrect!' />
                <LogInForm handleWrongCredentials={ handleWrongCredentials }/>
                <NavigationButton url='/SignUp' text='SIGNUP' styleName='sign-up-button'/>
            </div>
            </div>
            
        </>
    )
}