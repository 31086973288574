import React from 'react';
import SessionContainer from './SessionContainer';

/**
 * This component displays a list of all the file names uploaded by the user.
 * 
 * @author Malaikah Hussain, Elena Scobici
 */

function UserUploads({uploads, fetchData}) {
  console.log(uploads);
  return (
    <div>
      {uploads.map((upload) => (
        <SessionContainer analysisId={upload.analysis_id}
          fileName={upload.files[0].file_name}
          date={upload.created_at}
          summarizedResult={upload.summarized_result}
          detailedResult={upload.detailed_result}
          fetchData={fetchData}>
        </SessionContainer>
      ))}
    </div>
  );
}

export default UserUploads;