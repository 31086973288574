/**
 * Create the React component for the line graph on the admin
 * dashboard page which plots the total number of user sessions over 
 * time.
 * 
 * @author Elena Scobici
 */

import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';

const SessionsLineChart = ({stats, size}) => {
    let statsMap = new Map(Object.entries(stats));
    if (size >= 1200) {
        size = 500;
    }

    // If the statsMap has more than 10 entries, only graph the 
    // 10 latest ones.
    if (statsMap.size > 10) {
        const entries = Array.from(statsMap.entries());
        const lastTenEntries = entries.slice(-10);
    
        statsMap = new Map(lastTenEntries);
    }

    const data = [];
    for (let [date, sessionsCount] of statsMap) {
        data.push({
            name: date,
            sessions_count: sessionsCount,
        })
    }

    return (
        <LineChart
        width={size}
        height={250}
        data={data}
        margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 25,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name">
            <Label value="Date" position="bottom" dy={5}></Label>
        </XAxis>
        <YAxis>
            <Label value="Sessions" position="left" dy={-20} dx={5} angle={-90}></Label>
        </YAxis>
        <Tooltip />
        <Line type="monotone" dataKey="sessions_count" stroke="var(--orange)" activeDot={{ r: 8 }} />
        </LineChart>
    )
}

export default SessionsLineChart;