import { BsLink } from "react-icons/bs";

export default function EventTitle({ link }) {
    // Helper function to check if the link is a YouTube URL and extract the video ID
    function getYouTubeThumbnail(link) {
        const match = link.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\s*[^\/\n\s]+\/|channel\/|user\/|playlist\?list=|watch\?v=|&v=)|youtu.be\/)([^\"&?\/\s]{11})/i);
        if (match && match[1]) {
            return `https://img.youtube.com/vi/${match[1]}/0.jpg`;
        }
        return null;
    }

    // Decide what to render based on the type of link
    const youtubeThumbnail = link ? getYouTubeThumbnail(link) : null;

    if (!link || !youtubeThumbnail) {
        return (
            <div className="event-card-title-container" style={{ background: '#9cd0c2',width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <BsLink size={200} />  
            </div>
        );
    }

    return (
        <div 
            className="event-card-title-container" 
            style={{ 
                backgroundImage: `url(${youtubeThumbnail})`,
                backgroundSize: '105%',    
                backgroundPosition: 'center', 
                backgroundRepeat: 'no-repeat', 
                width: '100%',
                height: '100%'
            }}
        >
        </div>
    );
}