import { useState, useEffect } from "react";
import EventsPageBanner from "./EventsPageElements/Banner/EventsPageBanner";
import EventsFilter from "./EventsPageElements/Filter/EventsFilter";
import EventsList from "./EventsPageElements/List/EventsList";
import EventsPagination from "./EventsPageElements/Pagination/EventsPagination";
import { SAMPLE_EVENTS } from '../../utils/constants';
import { getNumberOfEventsToShow } from "./eventsPageFunctions";
import './eventsPage.css';
import { BACKEND_URL } from '../../utils/constants';


export default function EventsPage() {
  const [currentShowcase, setCurrentShowcase] = useState(1);
  const [typeFilters, setTypeFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [eventsList, setEventsList] = useState([]);

  async function fetchData() {
    try {          
    const url = `${BACKEND_URL}/events`;     
    const response = await fetch(url);

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }else{
      console.log("Succesfull");
    }
    const data = await response.json();
    setEventsList(data);
    } catch (error) {
    console.error('Error fetching:', error);
    }
    }

  useEffect(() => {
    fetchData()}
  , []);

  let numToShow = getNumberOfEventsToShow();

  const handleTypeFilterChange = (e) => {
    const type = e.currentTarget.id;
    if (typeFilters.includes(type)) {
      const newTypeFilters = typeFilters.filter((filter) => filter !== type);
      setTypeFilters(newTypeFilters);
    } else {
      const newTypeFilters = [...typeFilters, type];
      setTypeFilters(newTypeFilters);
    }
  }

  return (
    <div className="events-container">
        <EventsPageBanner />
        <EventsFilter 
          activeTypeFilters={typeFilters}
          currentDateFilter={dateFilter}
          searchFilterSetter={setSearchFilter}
          />
        <EventsList eventList={eventsList} 
          showcaseSize={numToShow} 
          currentPage={currentShowcase}
          searchFilter={searchFilter}
        />
        <EventsPagination showcaseSize={numToShow} 
          numOfEvents={eventsList.length}
          showcaseSetter={setCurrentShowcase}
          currentShowcase={currentShowcase}
        />
    </div>
  );
}