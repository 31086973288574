import React from 'react'
import '../generalStyle.css'
import triangle1 from '../../../images/triangle-1.svg'
import triangle2 from '../../../images/triangle-2.svg'
import triangle3 from '../../../images/triangle-3.svg'

export default function Background() {
    return (
        <>
            <img src={ triangle1 } className='triangle-1' />
            <img src={ triangle2 } className='triangle-2' />
            <img src={ triangle3 } className='triangle-3' />            
        </>
    )
}