/**
    Create a React component representing the banner
    at the top of the training page.

    @author Sergio Perez
*/

import './trainingBanner.css';
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { IoBulbOutline } from "react-icons/io5";
import { GoGear } from "react-icons/go";

export default function TrainingBanner() {
    return (
        <div className="training-banner">
            <IoBulbOutline className="training-banner-bulb" />
            <GoGear className="training-banner-gear" />
            <h1 className="training-banner-text">Training</h1>
            <LiaChalkboardTeacherSolid className="training-banner-chalkboard" />
        </div>
    )
}