/**
    Create a React component representing each team section
    on the About Us Page. This card contains the TeamMemberCard
    component, which contains the actual team member information,
    including the member's name, image, and description (if applicable).

    @author Cassandra Tin Kwon Yuen, Sergio Perez
*/


import TeamBanner from "./TeamBanner";
import TeamMemberCard from "./TeamMemberCard";

export default function TeamSection({ teamName, teamMembers }) {

    return (
        <div className="team-card-container">
            <TeamBanner teamName={ teamName } list={ false }/>
            <div className="team-members-wrapper">
                { teamMembers.map((teamMember) => {
                    return (
                        <TeamMemberCard 
                            name={teamMember.name}
                            picture={teamMember.picture}
                            description={teamMember.description}
                        />
                    )
                })}
            </div>
            
        </div>
    )
}