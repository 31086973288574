/**
    Create a React component representing the list of
    lessons on the training page. 
    The content of the lessons comes from utils/lessons.js.
    The main lessons come first in order, then the optional
    lessons in order. 

    @author Sergio Perez, Cassandra Tin Kwon Yuen
*/

import LessonCard from "./Card/LessonCard";
import { LESSONS } from "../../../../utils/lessons";
import "./lessonList.css";
import { useState, useEffect, useContext } from "react"; 
import { AuthContext } from "../../../AuthenticationPages/AuthContext";
import { BACKEND_URL } from "../../../../utils/constants";

export default function LessonList() {
    const [completedProgress, setCompletedProgress] = useState({});
    const { userId, token } = useContext(AuthContext);
    localStorage.setItem('userId', userId);
    const storedUserId = localStorage.getItem('userId');
    useEffect(() => {
        const fetchTrainingProgress = async () => {
          try {
            const response = await fetch(`${BACKEND_URL}/get-training?userId=${storedUserId}`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
              console.error("Failed to fetch training progress");
              return;
            }
            const data = await response.json();
            setCompletedProgress(data);
            console.log(data);
          } catch (error) {
            console.error('Error fetching training progress:', error);
          }
        };
    
        fetchTrainingProgress();
      }, []);

    let lessonList = LESSONS.map((lesson) => {
        return <LessonCard key={lesson.number} props={lesson} completedProgress={completedProgress} />
    });

    // Sort the lesson list by type. This will put all the main lessons first, then all the optional lessons.
    // If both lessons are main lessons, then we will sort by their number.
    lessonList.sort((lesson1, lesson2) => {
        if (lesson1.props.type === "main" && lesson2.props.type === "optional") {
            return -1;
        }
        else if (lesson1.props.type === "optional" && lesson2.props.type === "main") {
            return 1;
        }
        else if (lesson1.props.type === "main" && lesson2.props.type === "main") {
            return lesson1.props.number - lesson2.props.number;
        }
        else {
            return 0;
        }
    });

    return (
        <div className="lesson-list-container">
            {lessonList}
        </div>
    );
}