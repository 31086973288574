import styled from "styled-components";

export const  ToolBoxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ToolBox = styled.div`
    background-color: var(--white);
    margin-top: 3rem;  /* margin from top of the screen */
    min-height: 35rem;
    border-radius: 25px;
    min-width:  85%; /* Adjust the width based on viewport width */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vw;
`;

export const LogoContainer = styled.div`
    opacity: 50%;
    border-radius: 50rem 15rem;
    padding: .25rem;
    min-width: 20vw;
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(1.5rem + 2vmin); // set custom font size based on screen
    color: #000;
    font-family: "Mulish";
    font-weight: bold;    

    @media screen and (max-width: 500px) { // Decrease font for small screens
        font-size: 7vw;
    }
`;

export const SubHeading = styled.div`
    font-style: italic;
    font-size: calc(1rem + 0.5vmin);
`

export const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1 1 0;
    gap: 20px;
    margin: 40px 0px;

    @media screen and (max-width: 1000px) { // Mobile view
        flex-direction: column;
    }
`

export const DragDrop = styled.div`
    justify-content: center; /* makes the text centered */
    border: 5px dashed var(--middle-purple);
    background-color: var(--light-purple);
    height: 100%;
    width: 50%;
    border-radius: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;     

    @media screen and (max-width: 1000px) { // Mobile view
        width: 90%;
    }
        
`;

export const DragDropMessage = styled.p`
    font-size: calc(1rem + 0.5vmin);
`

export const SubmitContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`

export const UploadedFileContainer = styled.div`
    filter: drop-shadow(0 0 0.5rem #ededed);
    background-color: white;
    padding: 5px;
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`

export const FileIcon = styled.img`
    display: block;
    max-height: 100%;
    min-height: 30px !important;
    height: auto;
    min-width: 30px;
    width: auto !important;
`

export const FileNameText = styled.p`
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: left;
`

export const EmptyDiv = styled.div`
    display: block;

    @media screen and (max-width: 1000px) { // Mobile view
        display: none;
    }
`



