import EventTitle from "./CardElements/EventTitle";
import EventContent from "./CardElements/EventContent";
import EventFooter from "./CardElements/EventFooter";
import { getEventColor } from "./eventCardFunctions";
import "./eventCard.css";

export default function EventCard({ 
    eventTitle, eventDescription, eventLink
 }) {

    const openInNewTab = (url) => {
        if (!url) return;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className="event-card-container" onClick={() => openInNewTab(eventLink)}>
            <EventTitle link={eventLink} />
            <EventContent title={eventTitle} description={eventDescription} />
        </div>
    );

}