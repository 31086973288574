/**
    Create a React component representing the contact 
    information field (email). This contains the 
    email and the corresponding icon for email.
    

    @author Cassandra Tin Kwon Yuen, Sergio Perez
*/


export default function ContactField({ fieldName, fieldInfo, fieldImage }) {
    return (
        <div className="contact-field-container">
            <div className="contact-icon">{ fieldImage }</div>
            <div className="contact-field-name">{ fieldName } :</div>
            <div className="contact-field-info">
                { fieldName === "Email" ? <a href={`mailto:${ fieldInfo }`} id="contact-email">{ fieldInfo }</a> : fieldInfo }</div>
        </div>
    )
}