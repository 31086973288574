import SignUpForm from './SignUpForm';
import ErrorBanner from '../ErrorElements/ErrorBanner';
import NavigationButton from '../MiscElements/NavigationButton';
import Background from '../MiscElements/Background';
import { useState } from 'react';
import logo from "../../../images/logo.png";
import '../generalStyle.css';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

export default function SignIn() {
    const [ emailAlreadyExists, setEmailAlreadyExists ] = useState(false);
    const [ diffPasswords, setDiffPasswords ] = useState(false);
    const [usedUsername, setUsedUsername] = useState(false);
    const [emptyFields, setEmptyFields] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);

    function handleEmailAlreadyExists(newValue) {
        setEmailAlreadyExists(newValue);
    }

    function handleDiffPasswords(newValue) {
        setDiffPasswords(newValue);
    }
    function handleUsedUsername(newValue) {
        setUsedUsername(newValue);
    }

    function handleEmptyFields(newValue) {
        setEmptyFields(newValue);
    }

    function handleWeakPassword(newValue) {
        setWeakPassword(newValue);
    }

    return (
        <>
            <div id='general'>
            <Background />
            <Link to="/" className='home-icon'>
                <FaHome url='/'/>
            </Link>
            <div className='container'>
                <img className='wisest-logo' src= {logo} alt='Wisest logo' />
                <ErrorBanner isActive={ emailAlreadyExists } text='Uh-oh! This email is already registered!' />
                <ErrorBanner isActive={ diffPasswords } text="Uh-oh! Your passwords don't match!" />
                <ErrorBanner isActive={ usedUsername } text='Uh-oh! This username is already taken!' />
                <ErrorBanner isActive={ emptyFields } text='Uh-oh! Please fill in all the fields!' />
                <ErrorBanner isActive={weakPassword} text='Uh-oh! Your password has to include at least 8 characters, 1 digit, 1 lowercase, 1 uppercase!' />
                <SignUpForm 
                    handleEmailAlreadyExists={ handleEmailAlreadyExists }
                    handleDiffPasswords={ handleDiffPasswords }
                    handleUsedUsername={handleUsedUsername}
                    handleEmptyFields={handleEmptyFields}
                    handleWeakPassword={handleWeakPassword}
                    />
                <NavigationButton url='/login' text='LOGIN' styleName='log-in-button'/>
            </div>
            </div>
        </>
    )
}