import "./eventsPageBanner.css";
import { FaRegClock } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa";
import { MdOutlineCalendarMonth } from "react-icons/md";

export default function EventsPageBanner() {
    return (
        <div className="events-banner">
            <FaRegStar className="events-left-star-1"/>
            <FaRegStar className="events-left-star-2"/>
            <FaRegClock className="events-clock"/>
            <h1 className="events-banner-text">Media</h1>
            <MdOutlineCalendarMonth className="events-calendar-icon"/>
            <FaRegStar className="events-right-star"/>
        </div>
    );
}