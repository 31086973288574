/** 
    Create a React component representing the swap button
    for switching between showing only the report page,
    and showing both upload and report.

     @author Sergio Perez
*/


export default function SideViewButton({ 
    label, isActive, sideViewOpenSetter, sideViewOpen 
}) {
    const handleSideViewToggle = () => {
        sideViewOpenSetter(!sideViewOpen);
    }

    let button = [];
    if (isActive) {
        button = (
            <button className={`display-upload-button ${!sideViewOpen ? "left-display-upload-button" : ""}`}
                onClick={handleSideViewToggle}
            >
                {label}
            </button>
        );
    }

    return (
        <>{button}</>
    );
}