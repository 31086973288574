/**
 * Create the React component for the picture graph on the admin
 * dashboard page representing the various degrees to which users
 * recommend the WISEST tool.
 * 
 * @author Elena Scobici
 */

import React from "react";
import YellowPerson from "../../images/person-yellow.png"
import GreenPerson from "../../images/person-green.png"
import PurplePerson from "../../images/person-purple.png"
import './AdminDashboard.css';

const LikelyToRecommendGraph = ({stats}) => {
    let statsMap = new Map(Object.entries(stats));
    // Create an array of all of the person images and rating numbers.
    const yellowPeople = [];
    const yellowNumbers = [];
    const greenPeople = [];
    const greenNumbers = [];
    const purplePeople = [];
    const purpleNumbers = [];
    let detractors = 0;
    let passives = 0;
    let promoters = 0;
    for (let i = 1; i < 7; i++) {
        yellowPeople.push(<img class="people-icons" src={YellowPerson}></img>)
        yellowNumbers.push(<p>{i}</p>)
        detractors += statsMap.get(String(i)) ? statsMap.get(String(i)) : 0
    }
    for (let i = 7; i < 9; i++) {
        greenPeople.push(<img class="people-icons" src={GreenPerson}></img>)
        greenNumbers.push(<p>{i}</p>)
        passives += statsMap.get(String(i)) ? statsMap.get(String(i)) : 0
    }
    for (let i = 9; i < 11; i++) {
        purplePeople.push(<img class="people-icons" src={PurplePerson}></img>)
        purpleNumbers.push(<p>{i}</p>)
        promoters += statsMap.get(String(i)) ? statsMap.get(String(i)) : 0
    }
    const totalVoters = detractors + passives + promoters;

    return (
        <div class="likely-recommend-graph-container">
            <div class="yellow-people">
                <div class="numbers-container">
                    {yellowNumbers}
                </div>
                <div class="people-container">
                    {yellowPeople}
                </div>
                <div class="border-line-container">
                    <div class="border-line"></div>
                </div>
                <p class="recommend-percent">{Math.round(detractors/totalVoters * 100)}%</p>
                <p>Detractors</p>
            </div>
            <div class="green-people">
                <div class="numbers-container">
                    {greenNumbers}
                </div>
                <div class="people-container">
                    {greenPeople}
                </div>
                <div class="border-line-container">
                    <div class="border-line"></div>
                </div>
                <p class="recommend-percent">{Math.round(passives/totalVoters * 100)}%</p>
                <p>Passives</p>
            </div>
            <div class="purple-people">
                <div class="numbers-container">
                    {purpleNumbers}
                </div>
                <div class="people-container">
                    {purplePeople}
                </div>
                <div class="border-line-container">
                    <div class="border-line"></div>
                </div>
                <p class="recommend-percent">{Math.round(promoters/totalVoters * 100)}%</p>
                <p>Promoters</p>
            </div>
        </div>
    )
}

export default LikelyToRecommendGraph