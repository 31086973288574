/**
    Create a React component representing a scale from 1 to 10
    in the About Us page feedback form. 
    There may be labels for "Not likely" and "Highly likely" next
    to the values 1 and 10.


    @author Cassandra Tin Kwon Yuen
*/

import React from 'react';


const Scale = ({ label, name, selectedValue, handleValueChange, likely, labelClass }) => {
  return (
    <div className="feedback-input" id={name}>
        {label !== "" && <label className={labelClass ? labelClass : "feedback-text"}>{label}</label>}
        <div className="scale-grid">
          <div className="feedback-text scale-text not-likely">{likely ? "Not likely" : ""}</div>
          <div className="feedback-text">
          <div className="scale">
            <div className="radio-container">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                <div key={value} className="grid-item">
                    <label htmlFor={`${name}${value}`} className="bar-label">
                    {value}
                    </label>
                    <input
                    className="radio-scale"
                    type="radio"
                    id={`${name}${value}`}
                    name={name}
                    value={value}
                    checked={selectedValue === value}
                    onChange={() => handleValueChange(name, value)}
                    />
                </div>
                ))}
            </div>
            </div>
          </div>
          <div className="feedback-text scale-text highly-likely">{likely ? "Highly likely" : ""}</div>
        </div>
      </div>
  );
};

export default Scale;
