import { Link } from "react-router-dom";

export const LESSONS = [
    {
        type: "main",
        number: 1,
        header: "Systematic Reviews and Protocols",
        
        content: (
            <>
                <p className="training-text">
                Read OSF page – Wiki, Student background information, and Student training sections
                </p>
                <ul className="training-list">
                    <li className="training-list-item">
                        All materials and document are found in the files section of the “Student training” component
                    </li>
                </ul>

                <h4 className="training-subtitle">
                    Coursera
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    Lectures on what is a systematic review and importance of a study protocol
                    </li>
                    <li className="training-list-item">
                    This is a meta-analysis course taught by two excellent researchers - Dr Tianjing Li, and Dr Kay Dickerson, both from the Cochrane Collaboration 
                    </li>
                    <li className="training-list-item">
                    Sign up and listen to the introductory videos, and the videos in Week 5 - Planning the Meta-Analysis and Statistical Methods:{" "}
                    <Link to="https://www.coursera.org/learn/systematic-review/home/welcome" className="training-link" target="_blank">
                        Coursera Systematic Review Course
                    </Link>
                    </li>
                </ul>
                

                <p className="training-text">{"\n"}View lessons here:</p>
                <ul className="training-list">
                    <li className="training-list-item">
                        <Link to="https://www.coursera.org/lecture/systematic-review/lecture-1a-introduction-to-systematic-reviews-eCyR3" className="training-link" target="_blank">
                            Lecture 1A: Introduction to Systematic Reviews
                        </Link>
                    </li>
                    <li className="training-list-item">
                        <Link to="https://www.coursera.org/lecture/systematic-review/lecture-1b-introduction-to-meta-analysis-ygx99" className="training-link" target="_blank">
                            Lecture 1B: Introduction to Meta-analysis
                        </Link>
                    </li>
                    <li className="training-list-item">
                        <Link to="https://www.coursera.org/learn/systematic-review/home/week/5" className="training-link" target="_blank">
                            Planning the Meta-Analysis and Statistical Methods
                        </Link>
                    </li>
                </ul>
                <h4 className="training-subtitle">
                    Modules
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                        Read modules (1, 5-7) on what is a systematic review, defining the research question, and eligibility criteria
                    </li>
                    <li className="training-list-item">
                        Read the modules (1, 5-6) Q&A
                    </li>
                    <li className="training-list-item">
                        Read PRISMA-P instruction manual and the checklist
                    </li>
                    <li className="training-list-item">
                        <i>(Optional)</i> Exercise 1: Using the PRISMA-P checklist, assess 1 systematic review protocol (Chan 20-19 or Scott 2017) independently (by yourself)
                    </li>
                </ul>
            </>
        )
    },
    {
        type: "main",
        number: 2,
        header: "ROBIS",
        content: (
            <>
                <h4 className="training-subtitle">
                    Lecture on ‘Risk of Bias in Systematic reviews’
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                        Watch a presentation online on ROBIS by one of the coauthors of this study, Dr. Penny Whiting:{" "}
                        <Link to="https://youtu.be/jSSCRQa8PTg" className="training-link" target="_blank">
                         https://youtu.be/jSSCRQa8PTg
                        </Link>
                    </li>
                    <li className="training-list-item">
                        Carole Lunny slides (in files)
                    </li>
                </ul>
                <h4 className="training-subtitle">
                    Modules
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    Read modules 8, 11-12, 15
                    </li>
                    <li className="training-list-item">
                    Read the modules (8, 11-12, 15) Q&A
                    </li>
                    <li className="training-list-item">
                    <i>(Optional)</i> Do an online tutorial on how to use Excel (use of filters, drop down boxes, ordering, etc)
                    </li>
                    <li className="training-list-item">
                    Read PRISMA instruction manual
                    </li>
                    <li className="training-list-item">
                    <i>(Optional)</i> Exercise 3: PRISMA assessment of 1 systematic review (Katchamart – use supplementary files or appendices)
                    </li>
                    <li className="training-list-item">
                    Read ROBIS instruction manual
                    </li>
                    <li className="training-list-item">
                    Read AMSTAR-2 materials
                    </li>
                    <li className="training-list-item">
                    Exercise 4: ROBIS assessments of 1 systematic review using blank ROBIS template in excel (Gaynes – use supplementary files or appendices)
                    </li>
                </ul>
            </>
        )
    },
    {
        type: "main",
        number: 3,
        header: "AMSTAR-2",
        content: (
            <>
                <h4 className="training-subtitle">
                    Lecture on AMSTAR-2 
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    Watch the videos (part 1 and 2) on AMSTAR-2 assessment at{" "}
                        <Link to="https://osf.io/wy3cn/" className="training-link" target="_blank">
                        https://osf.io/wy3cn/
                        </Link>
                    </li>
                </ul>
                <h4 className="training-subtitle">
                    Modules
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    Using the AMSTAR-2 tool, and our decision rules, assess the PCI review OR the Opioids for chronic pain reviews, located in the files section of{" "}
                        <Link to="https://osf.io/wy3cn/" className="training-link" target="_blank">
                        https://osf.io/wy3cn/
                        </Link>
                    </li>
                    <li className="training-list-item">
                    When done, (i) watch the .mp4 videos of the assessments, (ii) note any discrepancies you have 
                    </li>
                </ul>
            </>
        )
    },
    {
        type: "main",
        number: 4,
        header: "Meta-analysis",
        content: (
            <>
                <h4 className="training-subtitle">
                    Coursera
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    Watch the videos in Module 5 of the course:{" "}
                        <Link to="https://www.coursera.org/learn/systematic-review?irclickid=3HbXMu1E%3AxyPWWQyXyzsZxz6UkFU59UShWy4RM0&irgwc=1&utm_medium=partners&utm_source=impact&utm_campaign=3307331&utm_content=b2c" className="training-link" target="_blank">
                        Module 5 Videos
                        </Link>
                    </li>
                    <li className="training-list-item">
                    You will need to sign up for free
                    </li>
                </ul>
                <h4 className="training-subtitle">
                    Modules
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    Download RevMan and install on your computer: .exe file in OSF Lesson 5:{" "}
                    <Link to="https://osf.io/r5mep/" className="training-link" target="_blank">
                        https://osf.io/r5mep/
                        </Link>
                    </li>
                    
                    <li className="training-list-item">
                    Read the RevMan tutorial, and watch: {" "}
                    <Link to="https://toptipbio.com/enter-data-into-revman/" className="training-link" target="_blank">
                        https://toptipbio.com/enter-data-into-revman/
                        </Link>
                    </li>
                </ul>
            </>
        )
    },
    {
        type: "main",
        number: 5,
        header: "Risk of Bias in RCTs",
        content: (
            <>
                <h4 className="training-subtitle">
                Lecture on how to assess the risk of bias of an RCT
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    Make note of any questions
                    </li>
                    <li className="training-list-item">
                    Watch the following video:{" "}
                    <Link to="https://www.youtube.com/watch?v=0c3u_2ZoBdI" className="training-link" target="_blank">
                    Risk of bias assessment of RCTs
                        </Link>
                    </li>
                    <li className="training-list-item">
                    Read slides by Dr Aaron Tejani:{" "}
                    <Link to="https://prezi.com/yc2dald8srjo/?token=2e096a27c42ad9f48e5f42b12ed07b359b4608e21d78bc4dae859cf26e87d090&utm_campaign=share&utm_medium=copy" className="training-link" target="_blank">
                    Prezi Slides
                        </Link>
                    </li>
                </ul>
                <h4 className="training-subtitle">
                    Modules
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">Read instructions in how to use the Cochrane risk of bias tool</li>
                    <li className="training-list-item">Read module 10 on assessing risk of bias of studies</li>
                    <li className="training-list-item">Read the module 10 Q&A</li>
                    <li className="training-list-item">Read information about Attrition Bias (domain 5)</li>
                    <li className="training-list-item">
                        Exercise 1: Assess the risk of bias of 1 RCTs using the Blank excel risk of bias form on the following RCTs: Bansod
                    </li>
                    <li className="training-list-item">
                        Compare your assessments with one other person and make note of (a) differences between assessments, and (b) how it was resolved
                    </li>
                    <li className="training-list-item">Group discussion of the assessments</li>
                </ul>
            </>
        )
    },
    {
        type: "optional",
        number: 6,
        header: "Network Meta-Analysis",
        content: (
            <>
                <p className="training-text"><i>(optional as only if you choose to assess NMAs)</i></p>
                <ul className="training-list">
                    <li className="training-list-item">On your own, familiarise yourself with the differences between rapid reviews, scoping reviews, evidence maps, systematic reviews and literature reviews</li>
                </ul>
                <h4 className="training-subtitle">
                Lectures on ‘Systematic reviews with network meta-analyses (NMAs)’ 
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    NMA Learning Live Webinar series Part 1-4
                    </li>
                    <li className="training-list-item">
                    Example of assessing the reporting quality of a NMA. Watch the webinar: Part 1: Editorial considerations about methods
                    </li>
                    <li className="training-list-item">
                    Watch the following video:{" "}
                    <Link to="https://www.youtube.com/watch?v=I6gqY5GkwMs" className="training-link" target="_blank">
                    Practical in RevMan
                        </Link>
                    </li>
                    <li className="training-list-item">
                    Watch the following video:{" "}
                    <Link to="https://www.youtube.com/watch?v=vmtprDqZi8k" className="training-link" target="_blank">
                    Why we need a new risk of bias tool for network meta-analysis
                        </Link>
                    </li>
                    <li className="training-list-item">
                    Watch the following video:{" "}
                    <Link to="https://youtu.be/GhrvlwjkBfo" className="training-link" target="_blank">
                    Areti Angeliki Veroniki And Sofia Tsokani: Impact Of Reporting Guidelines 
                        </Link>
                    </li>
                   
                    <li className="training-list-item">
                    Watch the following video:{" "}
                    <Link to="https://youtu.be/Y4O3hMxN0Qk" className="training-link" target="_blank">
                    A Beginners Guide to Network Meta Analysis - Dr Chris Noone
                        </Link>
                    </li>
                </ul>
                <h4 className="training-subtitle">
                    Modules
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">Read the “Dias NMA Explained” PDF</li>
                    <li className="training-list-item">Read PRISMA NMA instruction manual</li>
                    <li className="training-list-item">
                        Read Cochrane handbook section on NMA:{" "}
                        <Link to="https://training.cochrane.org/handbook/current/chapter-11" className="training-link" target="_blank">
                         https://training.cochrane.org/handbook/current/chapter-11
                        </Link>
                    </li>
                    <li className="training-list-item">
                    Exercise 5: PRISMA NMA assessment of 1 systematic review with NMA (Li – use supplementary files or appendices)
                    </li>
                    <li className="training-list-item">
                        Exercise 1: Assess the risk of bias of 1 RCTs using the Blank excel risk of bias form on the following RCTs: Bansod
                    </li>
                    <li className="training-list-item">
                    Read the RoB NMA E&E document (guidance document)
                    <ul className="training-list">
                        <li className="training-list-item"> Do an assessment of an NMA with the tool by Cazzola 2017</li>
                    </ul>
                    </li>
                </ul>
            </>
        )
    },
    {
        type: "optional",
        number: 7,
        header: "Overviews of Review Methods",
        content: (
            <>
                <h4 className="training-subtitle">
                Lecture on ‘Overviews of systematic reviews’
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    Watch the following presentation:{" "}
                        <Link to="https://www.ti.ubc.ca/2020/01/31/feb-26-overviews/ " className="training-link" target="_blank">
                        https://www.ti.ubc.ca/2020/01/31/feb-26-overviews/ 
                        </Link>
                    </li>
                    <p className="training-text">(scroll down for the presentation)</p>
                </ul>
                <h4 className="training-subtitle">
                    Modules
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    Read the PRIOR reporting tool on how to report overviews of reviews 
                    </li>
                    
                    <li className="training-list-item">
                    Read one overview of reviews online and assess it using PRIOR
                    </li>
                </ul>
            </>
        )
    },
    {
        type: "optional",
        number: 8,
        header: "Machine Learning",
        content: (
            <>
                <h4 className="training-subtitle">
                Lectures
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                        <Link to="https://cifar.ca/ai/destinationai/" className="training-link" target="_blank">
                        Destination AI: Introduction to Artificial Intelligence
                        </Link>
                    </li>
                    <li className="training-list-item">
                        Watch the following video:{" "}
                        <Link to="https://www.youtube.com/watch?v=KALKqbuCCV0" className="training-link" target="_blank">
                        Prof James Thomas. Information Technologies for systematic reviews
                        </Link>
                    </li>
                </ul>
            </>
        )
    },
    {
        type: "optional",
        number: 9,
        header: "Journal Publishing and Peer Review",
        content: (
            <>
                <h4 className="training-subtitle">
                Modules
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">Read the paper “Peer review_Assessing Risk of Bias in Human Epidemiologic Studies Using Three Tools.pdf”</li>
                    <li className="training-list-item">
                    Read:{" "}
                        <Link to="https://www.springernature.com/gp/authors/campaigns/how-to-peer-review" className="training-link" target="_blank">
                        How to Peer Review 
                        </Link>
                        {" "}(10 min read)
                    </li>
                    
                </ul>
                <h4 className="training-subtitle">
                    Quiz
                </h4>
                <ul className="training-list">
                    <li className="training-list-item">
                    <Link to="https://www.onlineexambuilder.com/how-to-peer-review/exam-87197" className="training-link" target="_blank">
                    https://www.onlineexambuilder.com/how-to-peer-review/exam-87197
                        </Link>
                    </li>
                </ul>
            </>
        )
    },
];