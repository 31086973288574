/**
    Create a React component representing each team member's
    picture.

    @author Sergio Perez
*/


export default function TeamMemberPicture({ picture, name }) {
    return (
        <div className="team-member-picture-container">
            <img className="team-member-picture" src={picture} alt={name} />
        </div>
    )
}