/** 
    Create a React component representing the feedback form
    on the About Us page. The user must fill out at least 1
    question to successfully submit. The POST request is sent
    to the /submit-feedback endpoint.
    The user does not have to be logged in to submit feedback.
    The user must select ROBIS or AMSTAR2 in question A for
    the options to appear in the select field of question B.
    This form contains TextInput.jsx and Scale.jsx components.

     @author Cassandra Tin Kwon Yuen
*/

import React, { useContext, useState } from 'react';
import Scale from './Scale';
import TextInput from './TextInput';
import ErrorMessage from './ErrorMessage';
import ThankYouMessage from './ThankYouMessage';
import { AuthContext } from '../../AuthenticationPages/AuthContext';
import { BACKEND_URL } from '../../../utils/constants';

function checkFields(inputs) {
  const checkedRadiosCheckboxes = inputs.filter((input) => input.type === 'checkbox' 
                                  || input.type === 'radio').filter((input) => input.checked);
  const filledInputs = inputs.filter((input) => input.type !== 'checkbox' && 
                      input.type !== 'radio').filter((input) => input.value.trim().length > 0);

  return checkedRadiosCheckboxes.length + filledInputs.length > 0;
}


const FeedbackForm = () => {
  const { isLoggedIn, userId } = useContext(AuthContext);
  const [selectedTool, setSelectedTool] = useState('');
  const [options, setOption] = useState('');
  const [selectedScale, setSelectedScale] = useState({});
  const [selectedItem, setSelectedItem] = useState('');
  const [items, setItem] = useState([]);
  const [easeOfUse, setEaseOfUse] = useState('');
  const [selectedOrganized, setSelectedOrganized] = useState('');

  const [feedback, setFeedback] = useState({});
  const [limitexceeded, setLimitExceeded] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [noFeedback, setNoFeedback] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const handleScaleChange = (name, value) => {
    setSelectedScale((prevValues) => ({ ...prevValues, [name]: value }));
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const handleLimitExceeded = (isExceeded) => {
    setLimitExceeded(isExceeded);
    if (isExceeded) {
      setSubmitted(false); // Set submitted to false when limit is exceeded
    }
  };

  const handleToolChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedTool(selectedValue);

    // Set items based on the selected tool
    if (selectedValue === 'robis') {
      setItem([
        'Q.1.1',
        'Q.1.2',
        'Q.1.3',
        'Q.1.4',
        'Q.1.5',
        'Q.2.1',
        'Q.2.2',
        'Q.2.3',
        'Q.2.4',
        'Q.2.5',
        'Q.3.1',
        'Q.3.2',
        'Q.3.3',
        'Q.3.4',
        'Q.4.1',
        'Q.4.2',
        'Q.4.3',
        'Q.4.4',
        'Q.4.5',
        'Q.4.6',
        'Q.A',
        'Q.B',
        'Q.C',
    ]);
    } else if (selectedValue === 'amstar') {
      setItem([
            'Q.1',
            'Q.2',
            'Q.3',
            'Q.4',
            'Q.5',
            'Q.6',
            'Q.7',
            'Q.8',
            'Q.9',
            'Q.10',
            'Q.11',
            'Q.12',
            'Q.13',
            'Q.14',
            'Q.15',
            'Q.16',
        ]);
    } else {
      setItem([]);
    }
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [e.target.name]: selectedValue,
    }));

    // Reset selected item and option
    setSelectedItem('');
  };

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    setOption(selectedValue);
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [e.target.name]: selectedValue,
    }));
  }

  const handleItemChange = (e) => {
    setSelectedItem(e.target.value);
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEaseOfUseChange = (value) => {
    setEaseOfUse(value);
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      ["easeOfUse"]: value,
    }));
  };

  const handleOrganizedChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOrganized(selectedValue);
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      ["organized"]: selectedValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const inputs = Array.from(form.elements);

    const atLeastOneFieldFilled = checkFields(inputs);

    if (atLeastOneFieldFilled) {
      setSubmitted(true);
      setNoFeedback(false);
      console.log("AuthContext:", { isLoggedIn, userId });
      const feedbackResponse = isLoggedIn ? { ...feedback, userId } : feedback;
      console.log(feedbackResponse);
      

      // Send POST request to backend
      try {
        const response = await fetch(`${BACKEND_URL}/submit-feedback`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(feedbackResponse),
        });

        const data = await response.json();
        console.log(data);

        if (response.ok) {
          console.log('Feedback submitted successfully!');
        } else {
          console.error('Failed to submit feedback');
        } 
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    } else {
      setSubmitted(false);
      setNoFeedback(true);
    }
  }

  return (
    <div id="about-us-feedback">
    <form onSubmit={ handleSubmit } className="feedback-form">
      <div className="feedback-header">1) WISEST AI Performance</div>
      <div className="feedback-subheading">A. Do you disagree with any of the judgments made/provided by WISEST?</div>
      <div>
      <div className="feedback-input">
        <label className="feedback-text">
          Please indicate which tool (ROBIS or AMSTAR 2)
        </label>
      <div id="tool">
        <label className="feedback-text">
          <input
            type="radio"
            name="tool"
            value="robis"
            checked={selectedTool === 'robis'}
            onChange={handleToolChange}
          />
          ROBIS
        </label>

        <label className="feedback-text">
          <input
            type="radio"
            name="tool"
            value="amstar"
            checked={selectedTool === 'amstar'}
            onChange={handleToolChange}
          />
          AMSTAR2
        </label>
      </div>
      </div>
    </div>
      <TextInput name="judgement" value={feedback.judgement} handleChange={handleChange} onLimitExceeded={handleLimitExceeded}
      label="Please provide the judgement you think is correct with the quote to back it up from the PDF and your rationale." />
      <div className="feedback-subheading">B. Was the quote the AI chose relevant to the item in question?</div>
      <div className="feedback-input">
        <label className="feedback-text">
          Please choose one item from the list:
          <select name="question" className="select-box" value={feedback.selectedItem} onChange={handleItemChange}>
            <option value="">Select item</option>
            {items.map((item) => (
              <option key={item} value={feedback.item}>
                {item}
              </option>
            ))}
          </select>
        </label>
      </div>
      
      <div className="feedback-input">
        <label className="feedback-text">
          Please choose one option from the list:
        </label>
      <div>
        <div className="mc">
            <input
              type="radio"
              name="option"
              value="Yes"
              checked={options === 'Yes'}
              onChange={handleOptionChange}
            />
            <label className="feedback-text">
            Yes, the quote was relevant.
          </label>
        </div>
        
        <div className="mc">
            <input
              type="radio"
              name="option"
              value="No"
              checked={options === 'No'}
              onChange={handleOptionChange}
            />
            <label className="feedback-text">
            No, the quote was not relevant.
          </label>
        </div>
        <div className="mc">
          <input
            type="radio"
            name="option"
            value="Yes but found another that was more relevant"
            checked={options === 'Yes but found another that was more relevant'}
            onChange={handleOptionChange}
          />
         <label className="feedback-text">
          Yes, the quote was relevant but I found another part of the paper relevant as well.
        </label>
        </div>
      </div>
      </div>
      <TextInput name="relevantQuote" value={feedback.relevantQuote} handleChange={handleChange} onLimitExceeded={handleLimitExceeded}
      label="If you choose option 2 or 3, please copy and paste the quote you think is relevant and why." />
      <div className="feedback-subheading">
        
        C. I feel like the text suggested by the AI to support an item or feature was often irrelevant</div>
      <Scale name="relevance" selectedValue={feedback.relevance} handleValueChange={handleScaleChange} 
      label="" likely={true}/>

      <div className="feedback-header"> 2) Website Performance and Usability</div>
      <div className="feedback-input">
        <label className="feedback-text">
          What would you say about the ease of use of our WISEST AI tool?
        </label>
        <div className="mc">
          <input
            type="radio"
            id="extremelyEasy"
            name="easeOfUse"
            value="extremelyEasy"
            checked={easeOfUse === 'extremelyEasy'}
            onChange={() => handleEaseOfUseChange('extremelyEasy')}
          />
          <label className="feedback-text" htmlFor="extremelyEasy">Extremely easy</label>
        </div>
        <div className="mc">
          <input
            type="radio"
            id="fairlyEasy"
            name="easeOfUse"
            value="fairlyEasy"
            checked={easeOfUse === 'fairlyEasy'}
            onChange={() => handleEaseOfUseChange('fairlyEasy')}
          />
          <label className="feedback-text" htmlFor="fairlyEasy">Fairly easy</label>
        </div>
        <div className="mc">
          <input
            type="radio"
            id="neutral"
            name="easeOfUse"
            value="neutral"
            checked={easeOfUse === 'neutral'}
            onChange={() => handleEaseOfUseChange('neutral')}
          />
          <label className="feedback-text" htmlFor="neutral">Neutral</label>
        </div>
        <div className="mc">
          <input
            type="radio"
            id="slightlyHard"
            name="easeOfUse"
            value="slightlyHard"
            checked={easeOfUse === 'slightlyHard'}
            onChange={() => handleEaseOfUseChange('slightlyHard')}
          />
          <label className="feedback-text" htmlFor="slightlyHard">Slightly hard</label>
        </div>
        <div className="mc">
          <input
            type="radio"
            id="extremelyHard"
            name="easeOfUse"
            value="extremelyHard"
            checked={easeOfUse === 'extremelyHard'}
            onChange={() => handleEaseOfUseChange('extremelyHard')}
          />
          <label className="feedback-text" htmlFor="extremelyHard">Extremely hard</label>
        </div>
      </div>
      <TextInput name="appearance" value={feedback.appearance} handleChange={handleChange} onLimitExceeded={handleLimitExceeded}
      label="Would you like to give any feedback about the appearance of the WISEST main report page, and sub-report pages?" />
      <Scale name="recommend" selectedValue={feedback.recommend} handleValueChange={handleScaleChange} 
      label="1. Based on your experience, how likely are you to recommend our AI tool?" likely={true}/>
      <Scale name="frequent" selectedValue={feedback.frequent} handleValueChange={handleScaleChange} 
      label="2. I would use the WISEST tool frequently" likely={true}/>
      <Scale name="easy" selectedValue={feedback.easy} handleValueChange={handleScaleChange} 
      label="3. I thought the tool was easy to use" likely={true}/>
      <Scale name="support" selectedValue={feedback.support} handleValueChange={handleScaleChange} 
      label="4. I think I would need the support of a technical person to use the tool" likely={true}/>
      <Scale name="integrated" selectedValue={feedback.integrated} handleValueChange={handleScaleChange} 
      label="5. I found the various functions in the tool were well integrated" likely={true}/>
      <Scale name="quality" selectedValue={feedback.quality} handleValueChange={handleScaleChange} 
      label="6. I would like to continue to use this tool to aid in assessing SRs for quality" likely={true}/>
      <Scale name="compete" selectedValue={feedback.compete} handleValueChange={handleScaleChange} 
      label="7. I would like to continue to use this tool to aid in choosing amongst competing SRs on the same topic" likely={true}/>
      <TextInput name="problems" value={feedback.problems} handleChange={handleChange} onLimitExceeded={handleLimitExceeded}
      label="8. What problems are you currently facing when using the tool?" />
      <TextInput name="often" value={feedback.often} handleChange={handleChange} onLimitExceeded={handleLimitExceeded}
      label="9. How often do you encounter this problem?" />
      <TextInput name="change" value={feedback.change} handleChange={handleChange} onLimitExceeded={handleLimitExceeded}
      label="10. What would you like to change about this tool?" />
      <TextInput name="know" value={feedback.know} handleChange={handleChange} onLimitExceeded={handleLimitExceeded}
      label="11. Is there anything you would like us to know?" />
      
      <div className="feedback-input" id="organized">
        <label className="feedback-text">
          12. Does the screen layout look organized to you?
        </label>
      </div>
      <div className="feedback-input">
        <label className="feedback-text">
          <input
            type="radio"
            name="yes"
            value="yes"
            checked={selectedOrganized === 'yes'}
            onChange={handleOrganizedChange}
          />
          Yes
        </label>

        <label className="feedback-text">
          <input
            type="radio"
            name="no"
            value="no"
            checked={selectedOrganized === 'no'}
            onChange={handleOrganizedChange}
          />
          No
        </label>
      </div>
      <TextInput name="add" value={feedback.add} handleChange={handleChange} onLimitExceeded={handleLimitExceeded}
      label="13. Is there anything you would like us to add to this screen?" />
      <Scale name="overall" selectedValue={feedback.overall} handleValueChange={handleScaleChange} 
      label="How would you like to rate the overall navigation experience?" likely={false}
      labelClass="feedback-subheading" />
      <button className="feedback-submit-button" type="submit">Submit</button>
      <ErrorMessage isActive={ noFeedback } text="Please enter your feedback"/>
      <ThankYouMessage isActive={ submitted } />
    </form>
    </div>
  );
};

export default FeedbackForm;
