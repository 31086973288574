/**
 * Create the React component for the admin dashboard page.
 * 
 * @author Elena Scobici
 */

import React, {useContext, useState, useEffect} from 'react';
import {AuthContext} from "../AuthenticationPages/AuthContext"
import Profile from "../UserDashboard/Profile"
import './AdminDashboard.css';
import SessionsLineChart from './SessionsLineChart';
import LikelyToRecommendGraph from './LikelyToRecommendGraph';
import ProblemRatePieGraph from './ProblemRatePieGraph';
import OverallRatingGraph from './OverallRatingGraph';
import VeryHappy from '../../images/rating-happy.png'
import Happy from '../../images/rating-happy-meh.png'
import Neutral from '../../images/rating-meh.png'
import Sad from '../../images/rating-meh-sad.png'
import VerySad from '../../images/rating-sad.png'
import CurrentEventCreator from './CurrentEventCreator';
import { BACKEND_URL } from '../../utils/constants';
import AdminGroupModal from './AdminGroupModal';

const AdminDashboard = () => {
    let currentlyLargeScreen = window.innerWidth >= 1000;
    const [size, setSize] = useState(window.outerWidth);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // tracks whether someone is logged in and their username (if they are)
    const { isLoggedIn, userName, token } = useContext(AuthContext);

    // Make the background light grey
    document.body.style.backgroundColor = "#fafafe";

    // Set the minHeight of the page container to make the footer 
    // properly align with the page bottom.
    let pageContainerStyle = {
        minHeight: 'calc(100vh - 100px - ' + 
            String(document.querySelector('.page-container')?.
                offsetTop) + 'px)',
    };

    // Helper method for calculating and applying new min height for
    // page-container based on its top offset.
    function updatePageContainerMinHeight() {
        currentlyLargeScreen = !currentlyLargeScreen;
        const pageContainer = document.
            querySelector('.page-container');
        if (pageContainer !== null) {
            pageContainer.style.minHeight = 'calc(100vh - 100px - ' + 
                String(pageContainer.offsetTop) + 'px)';
        }
    }

    // Change page-container height when navbar height switches,
    // at 1000 px viewport width.
    function handleResize() {
        setSize(window.outerWidth);
        if (window.innerWidth < 1000 == currentlyLargeScreen) {
            updatePageContainerMinHeight();
        }
    }

    // Listen for window resize and component loading so that
    // page-container minHeight can be updated.
    window.addEventListener('resize', handleResize);
    React.useEffect(() => {
        updatePageContainerMinHeight();
    }, []);

    const [userStats, setUserStats] = useState({});
    const [events, setEvents] = useState([]);
    const [feedbackStats, setFeedbackStats] = useState({});

    // Helper method for fetching data from the given endpoint and
    // setting the state of a variable using the given stateMethod.
    async function fetchData(endpoint, stateMethod) {
        try {          
        const url = `${BACKEND_URL}/${endpoint}`;     
        const response = await fetch(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (endpoint === "feedback-stats") {
            stateMethod(data.data);
            calculateAndSetBarHeights(feedbackStats.ease_of_use_counts);
        }
        else {
            stateMethod(data);
        }
        } catch (error) {
        console.error('Error fetching:', error);
        }
    }

    // Helper method for using the feedback-stats.ease_of_use_counts 
    // to calculate how tall each bar in the ease of use bar chart
    // should be, and setting their heights accordingly.
    const calculateAndSetBarHeights = (ease_of_use_counts) => {
        if (!ease_of_use_counts) { // Data has not yet been fetched.
            return;
        }
        let totalVotes = 0;
        for(var rating in ease_of_use_counts) {
            totalVotes += ease_of_use_counts[rating];
        };

        const idToDataKey = {
            'very-happy-bar': 'extremelyEasy',
            'happy-bar': 'fairlyEasy',
            'neutral-bar': 'neutral',
            'sad-bar': 'fairlyHard',
            'very-sad-bar': 'extremelyHard'
        }

        var maxBarCount = 0;

        for (var barId in idToDataKey) {
                const barCount = ease_of_use_counts[idToDataKey[barId]];
                maxBarCount = Math.max(maxBarCount, parseInt(barCount ? barCount : 0));
                const barHeight = String(Math.round((barCount ? barCount : 0) / totalVotes * 100)) + "%";
                document.getElementById(barId).style.height = barHeight;
                document.getElementById(barId + "-label").innerHTML = barHeight;
            };
        // Change the height of the bar chart container to get rid of
        // excess space.
        document.querySelector('.bars').style.height = 
            String(Math.round(250 * (maxBarCount / totalVotes))) + "px";
    }

    useEffect(() => {
            fetchData("user-stats", setUserStats);
            fetchData("events", setEvents);
            fetchData("feedback-stats", setFeedbackStats);
        }
        , []);

    useEffect(() => {
        calculateAndSetBarHeights(feedbackStats.ease_of_use_counts);
    }, [feedbackStats]);

    const formSubmit = (event) => {
        event.preventDefault(); // Don't redirect
        
        const eventForm = event.target;
        const formData = new FormData(eventForm);
        const plainFormData = Object.fromEntries(formData.entries());
	    const formDataJsonString = JSON.stringify(plainFormData);

        
        fetch(`${BACKEND_URL}/events`, {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: formDataJsonString,
        })
            .then((response) => {
                if (response.status === 201) {
                    alert("Event created!");
                    fetchData("events", setEvents);
                }
            })
            .catch((error) => {alert(error)});
        
        eventForm.reset();
    }

    const [descCharCount, setDescCharCount] = useState(0);

    const handleDescriptionChange = (e) => {
        setDescCharCount(e.target.value.length);
    }

    // Function to download the CSV file
    const downloadFeedbackCSV = async () => {
        try {
            const response = await fetch(`${BACKEND_URL}/export-feedbacks`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'text/csv'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch feedback CSV');
            }

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = "feedback.csv";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error downloading the CSV:', error);
        }
    };

    return (
        <div class="page-container" style={pageContainerStyle}>
            <div class="outer-profile-container">
                <Profile userName={userName} isLoggedIn={isLoggedIn} setIsModalOpen={setIsModalOpen}></Profile>
            </div>
            <div class="stats-container">
                <div class="stat-box violet-stat-box">USER STATS</div>
                <div class="stat-box green-stat-box">
                    <p class="stat-header-text">Total Users:</p>
                    <p class="stat-content-text">{userStats.num_of_sessions}</p>
                </div>
                <div class="stat-box green-stat-box">
                    <p class="stat-header-text">Total Sessions:</p>
                    <p class="stat-content-text">{userStats.num_of_users}</p>
                </div>
                <div class="stat-box green-stat-box">
                    <p class="stat-header-text">Total Files:</p>
                    <p class="stat-content-text">{userStats.num_of_files}</p>
                </div>
                
            </div>

            <AdminGroupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} token={token} />

            <div class="current-events-container">
                <p class="admin-header">Current Media</p>
                <div id="current-events-scroll">
                    <CurrentEventCreator events={events} fetchData={fetchData} setEvents={setEvents}></CurrentEventCreator>
                </div>
            </div>
            <div class="create-events-container">
                <p class="admin-header">Create Media</p>
                <form id="create-event-form" onSubmit={formSubmit}>
                    <div class="form-grid">
                        <label for="title">Title:</label>
                        <input id="title" name="title" required maxLength={30}></input>
                        <label for="link">Link:</label>
                        <input id="link" name="link" required></input>
                        <label for="description">Description:</label>
                        <textarea id="description" name="description" rows="7" onInput={handleDescriptionChange} maxLength={200} required></textarea>
                    </div>
                    <p id="descriptionCharCount">{descCharCount} / 200</p>
                    <div class="submit-container">
                        <button id="create-event-submit" type="submit">Save &gt;</button>
                    </div>
                </form>
            </div>
            <div class="feedback-stats-container">
                <div class="feedback-stats-container-header">
                    <p class="admin-header">Feedback Stats</p>
                    <button onClick={downloadFeedbackCSV} className="export-feedbacks-button">
                        Export Feedbacks
                    </button>
                </div>
                
                <div class="feedback-stats-grid-1">
                    <div class="feedback-stats-box column-box">
                        <p class="small-stats-text people-disagree-text"># of people who disagree</p>
                        <div class="stat-box green-stat-box padded-box">
                            <p class="smaller-header-text">ROBIS</p>
                            <p class="smaller-content-text">{feedbackStats.robis_count}</p>
                        </div>
                        <div class="stat-box green-stat-box padded-box">
                            <p class="smaller-header-text">AMSTAR</p>
                            <p class="smaller-content-text">{feedbackStats.amstar_count}</p>
                        </div>
                    </div>
                    <div class="feedback-stats-box">
                        <p class="small-stats-text"># of Sessions Over Time</p>
                        <div class="center-box">
                            {feedbackStats.session_counts && <SessionsLineChart stats={feedbackStats.session_counts} size={size - 30}></SessionsLineChart>}
                        </div>
                    </div>
                </div>
                <div class="feedback-stats-grid-2">
                    <div class="feedback-stats-box">
                        <p class="small-stats-text">Ease of Use</p>
                        <div class="center-box">
                            <div class="bar-chart-container">
                                {/* For each graph, make sure the 
                                stats have been fetched before trying 
                                to render them. */}
                                {/* {feedbackStats.ease_of_use_counts && <EaseOfUseBarChart stats={feedbackStats.ease_of_use_counts} size={graphSizes.ease_of_use}></EaseOfUseBarChart>} */}
                                <div class="bars">
                                    <div class="bar-container">
                                        <div class="bar-label" id="very-happy-bar-label">33%</div>
                                        <div class="ease-of-use-bar" id="very-happy-bar"></div>
                                    </div>
                                    <div class="bar-container">
                                        <div class="bar-label" id="happy-bar-label">33%</div>
                                        <div class="ease-of-use-bar" id="happy-bar"></div>
                                    </div>
                                    <div class="bar-container">
                                        <div class="bar-label" id="neutral-bar-label">33%</div>
                                        <div class="ease-of-use-bar" id="neutral-bar"></div>
                                    </div>
                                    <div class="bar-container">
                                        <div class="bar-label" id="sad-bar-label">33%</div>
                                        <div class="ease-of-use-bar" id="sad-bar"></div>
                                    </div>
                                    <div class="bar-container">
                                        <div class="bar-label" id="very-sad-bar-label">33%</div>
                                        <div class="ease-of-use-bar" id="very-sad-bar"></div>
                                    </div>
                                </div>
                                <div class="bar-chart-images">
                                    <img class="rating-image" src={VeryHappy}></img>
                                    <img class="rating-image" src={Happy}></img>
                                    <img class="rating-image" src={Neutral}></img>
                                    <img class="rating-image" src={Sad}></img>
                                    <img class="rating-image" src={VerySad}></img>
                                </div>
                            </div>
                            <div id="ease-of-use-scale">
                                <p>Easy</p>
                                <div id="ease-of-use-left"></div>
                                <div id="ease-of-use-line"></div>
                                <div id="ease-of-use-right"></div>
                                <p>Hard</p>
                            </div>
                        </div>
                    </div>
                    <div class="feedback-stats-box">
                        <p class="small-stats-text">How Likely to Recommend</p>
                        {feedbackStats.recommend_counts && <LikelyToRecommendGraph stats={feedbackStats.recommend_counts}></LikelyToRecommendGraph>}
                    </div>
                </div>
                <div class="feedback-stats-grid-1">
                    <div class="feedback-stats-box">
                        <p class="small-stats-text">Overall Rating</p>
                        {feedbackStats.overall_counts && <OverallRatingGraph stats={feedbackStats.overall_counts}></OverallRatingGraph>}
                    </div>
                    <div class="feedback-stats-box">
                        <p class="small-stats-text">Problem Rate</p>
                        <div class="center-box">
                            {feedbackStats.overall_counts && 
                                <ProblemRatePieGraph 
                                    problems={feedbackStats.problem_count}
                                    no_problems={feedbackStats.no_problem_count}
                                    size={size}>
                                </ProblemRatePieGraph>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard;