/**
   Styled components for the footer.

    @author Cassandra Tin Kwon Yuen
*/


import styled from "styled-components";


export const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    background-color: #262A35;
    margin-top: 3rem;
    ${props =>
    (props.currentPage === '/LogIn' || props.currentPage === '/SignUp' || props.currentPage === '/Results') &&
    `
          bottom: 0;
        `}
`

export const FooterText = styled.div`
    padding: 1rem 0;
    color: white;
`