/**
    Create a React component representing the footer of 
    the website. This will always be located at the bottom 
    of each page, except log-in and sign up.
    This contains the current year that will be updated
    automatically.

    @author Cassandra Tin Kwon Yuen
*/


import React from 'react';
import {FooterContainer, FooterText} from "./FooterElements";


const date = new Date();

const Footer = ({ isActive }) => {
    const currentPage = window.location.pathname;
    let footer = []
    if (isActive) {
        footer = (
            <FooterContainer currentPage={currentPage}>
                <FooterText>
                    &copy; {date.getFullYear()} Knowledge Translation Program
                </FooterText>
            </FooterContainer>
        )
    }
    
    return footer
};
    export default Footer;