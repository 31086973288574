/**
 *  This component represents the DevelopingWisest page. Users Can acess this page from the 
 *  Navbar.
 *  This contains graphs, timelines, tables, etc. describing how the WISEST tool was developed.
 * 
 *  @author  Alessandro Marmi, Aliyah James, Cassandra Tin Kwon Yuen
 */


import React from 'react';
import "./DevelopingWisest.css";
import {DevelopingWisestTimeline} from "./DevlopingWisestTimeline.js";
// Import react icons
import { GoGear } from "react-icons/go";
import { MdCategory, MdSummarize} from "react-icons/md";
import { BsClipboard2CheckFill, BsFillPeopleFill } from "react-icons/bs";
import { BiSolidBadgeCheck } from "react-icons/bi";
import { IoDocumentTextSharp } from "react-icons/io5";
// import images and SVGs
import SystematicReviewImage from "../../images/systematic_review.png";
import Logo from "../../images/logo.png";
import Map from "../../images/map.png";
import Developing from "../../images/developing_wide.png";
import RoadMapDesktop from "../../images/roadmap_wide.png";
import RoadMapMobile from "../../images/roadmap_vertical.png";
import PieChart from "../../images/pie_chart_words.png";
import Amstar from "../../images/amstar.png";
import Robis from "../../images/robis.png";
import Ai from "../../images/ai_develop_wide.png";



const DevelopingWisest = () => {
  return (
    <>
   <div className="body-developing">
    <div className="title-developing">
      <span>
        {/* smaller gear on the left */}
        <GoGear className="left-gear" style={{ position: 'absolute', transform: 'rotate(345deg)'}}/>
      </span>
      <span>
         {/* larger gear on the left */}
        <GoGear className="left-bottom-gear" style={{ position: 'absolute'}}/>
      </span>
      <div className="title-text">
        Developing WISEST
      </div>
      <span>
         {/* gear on the right */}
        <GoGear className="right-gear" style={{ position: 'absolute'}} />
      </span>
    </div>

    <div className="intro"> 
  <div className="text-content">
    <div className="text-above-image">
      <p>We aimed to develop a FREE and OPEN ACCESS AI model called WISEST (Which Systematic Evidence Synthesis is besT) to predict critical appraisal (i.e. using ROBIS & AMSTAR 2) judgments/ratings of systematic reviews.</p>
      WISEST will be based on empirical research and monitored by experts for accuracy on an ongoing basis.
    </div>
    <div className="text-close-to-image">
     <p> The code and supporting text for the AI outputs will be transparently documented.</p>
        <p>Our protocol is registered and located on the Open Science Framework at <a href="https://osf.io/nbcta/" target="_blank">https://osf.io/nbcta/</a></p>
    </div>

  </div>
  <div className="image-container">
    <img 
      src={SystematicReviewImage} 
      alt="Systematic Review Image" />
  </div>
    </div>

    <div className="purpose-of-different-tools">

      <table>
        <caption>PURPOSE OF DIFFERENT TOOLS</caption>
        <thead>
          <tr>
            <th>Tools for SRs</th>
            <th>Tool's purpose</th>
            <th>Description of the checklist or tool</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>AMSTAR 2</td>
            <td>Assess the quality of all types of systematic reviews</td>
            <td>AMSTAR-2 is a critical appraisal tool to assess the quality of conduct of reviews</td>
          </tr>
          <tr>
            <td>ROBIS</td>
            <td>Assess risk of bias of all types of systematic reviews with and without traditional (pairwise) meta-analysis</td>
            <td>ROBIS is a tool for assessing the risk of bias in reviews.</td>
          </tr>
          <tr>
            <td>RoB NMA tool</td>
            <td>Assess risk of bias of all types of network meta-analyses</td>
            <td>The RoB NMA tool addresses the degree to which the review methods lead to a risk of bias in the NMA results and conclusions</td>
          </tr>
          <tr>
            <td>GRADE</td>
            <td>Assess the certainty in a body of evidence in a systematic review with and without traditional (pairwise) meta-analysis</td>
            <td>The Grading of Recommendations Assessment, Development, and Evaluation (GRADE) approach defines the certainty of evidence as confidence that a pooled effect is close to the true effect. Five domains assessed: risk of bias, consistency, directness, precision, and publication bias. </td>
          </tr>
          <tr>
            <td>CINeMA, GRADE-NMA, Threshold method</td>
            <td>Assess the certainty in a body of evidence in an NMA</td>
            <td>CINeMA is a methodological framework to evaluate the  confidence in the results from network meta-analyses. The GRADE working group developed guidance to evaluate the certainty of evidence (confidence in evidence, quality of evidence) from network meta-analysis (NMA). The threshold analysis methods is used to determine the sensitivity to the evidence on individual comparisons (for NMA) and to each study. Further threshold analyses may then be used to address more specific concerns, such as biases in sets of treatment comparisons or studies.</td>
          </tr>
          <tr>
            <td>PRISMA Update</td>
            <td>Standards for reporting published reviews</td>
            <td>Minimum set of items for reporting in reviews and meta-analyses. </td>
          </tr>
          <tr>
            <td>PRISMA Update</td>
            <td>Standards for reporting published reviews</td>
            <td>Minimum set of items for reporting in reviews and meta-analyses.</td>
          </tr>
        </tbody>
      </table>

      <div>
        This table shows that several tools are designed with different purposes; some for assessing reporting quality, and some for assessing quality of conduct. 
      </div>

      <div className="info-section">
        <div className="info-item">
          <MdCategory className="info-icon" size={100} style={{color: '#EF761B'}}/>
          <p>For critically appraising the quality of reviews, the AMSTAR 2 checklist is the most commonly used tool, and aims to evaluate 
            the quality of conduct of systematic reviews with pairwise meta-analysis</p>
        </div>
        <div className="info-item">
          <BsClipboard2CheckFill className="info-icon" size={91} style={{  color: '#7a88bc'}}/>
          <p>ROBIS is a domain based tool used to assess the risk of bias in systematic review results and conclusions.</p>
        </div>
        <div className="info-item">
          <BiSolidBadgeCheck className="info-icon" size={110} style={{color: '#EBDF95'}}/>
          <p>To assess completeness of reporting of published systematic reviews, users can use the updated PRISMA statement.</p>
        </div>
        <div className="info-item">
          <img src={Logo} alt="Fourth Icon" className="info-icon"/>
          <p>There is currently no tool besides WISEST to automatically assess the quality and biases in systematic reviews.</p>
        </div>
      </div>
    </div>


   {/* TimeLine */}
    <div className='timeline-t1-container'>
      <div className='heading'>PRELIMINARY AND FUTURE RESEARCH</div>
      <p >Figure 2 below outlines our preliminary research and future objectives with timelines</p> 
      </div>
      <div className='timeline'> 
      <DevelopingWisestTimeline/>
      </div>
      <div className='timeline-t1-container'>
      <p>Our proposed methods follow a multi-step approach to tool development: </p>
      <ul className= 'progress-list'>
      <li className='developing-list-items'>First, a systematic review was conducted to identify tools to help users choose amongst multiple 
      SRs and a replication study was conducted based on the one (Jadad) tool we found (study i in Fig. 2 above).  
      </li>
      <li className='developing-list-items'>Second, we surveyed users to understand how they use systematic reviews in their work 
      (study ii in Fig. 2). </li>  
      <li className='developing-list-items'>Finally, we conducted a qualitative review to determine which features are used by researchers to 
      select reviews with different results and conclusions (study iii in Fig.2). 
      </li>       
    </ul>     
    </div>


    {/* Developing the AI Model */}
    <div className='ai-develop-container'>
      <img className="article-image" src={Ai} alt='Developing AI Chart'/> 
    </div>
    <div className='robis-container'> 
    <div className='tools-row-container'>
      <div className='tools-row'>
      <img className="article-image" src={ Robis } alt='Robis Article'/> 
      </div>
      <div className='tools-row'>          
      <strong className='robis'>ROBIS</strong>
      <p><strong className="tools-row-subheading">Domain based, signalling questions</strong></p>
      <p><strong>Overall judgment of bias in the review</strong></p>          
      <ul className= 'progress-list'>
        <li className='developing-list-items'>Did the reviewers address concerns relating to domains 1-4? </li>
        <li className='developing-list-items'>Was the relevance of included studies considered? </li>  
        <li className='developing-list-items'>Were effects interpreted appropriately?</li>       
      </ul> 
      </div> 
      </div>    
      <p><strong>The ROBIS Tool</strong> is designed to critically assess the risk of bias in systematic reviews.
            ROBIS is unique in that it is specifically designed to assess risk of bias, whereas other 
            tools focus on broad goals such as critical appraisal and quality assessment. ROBIS has been 
            developed using rigorous methodology and is currently aimed at four broad categories of reviews 
            mainly within healthcare settings: interventions, diagnosis, prognosis and aetiology.
        </p>
    </div>
    <div className='amstar-container'>
      <div className='tools-row-container'>
        <div className='tools-row'>
      <img className="article-image" src={ Amstar } alt='Amstar Article'></img> 
      </div>
      <div className='tools-row'>
      <strong className='amstar'>AMSTAR </strong>
      <p><strong className="tools-row-subheading">16 Questions</strong></p>   
      <ul className= 'progress-list'>
        <li className='developing-list-items'>Judgement for each item reported as: yes, no, partial yes</li>
        <li className='developing-list-items'>‘Not applicable’ and ‘Cannot answer’ responses removed</li>          
      </ul> 
      </div>
      </div>
      <p><strong>The Measurement Tool to Assess Systematic Reviews (AMSTAR) 2</strong> is a critical 
            appraisal tool for systematic reviews and meta-analyses that include randomised 
            or non-randomised studies of healthcare interventions, or both. The tool’s reliability 
            and validity have been tested and validated.
      </p>
    </div>
    
     
    {/* Features */}
    <div className='heading features'><div className="features-heading">FEATURES</div>
    <p><BiSolidBadgeCheck style={{position: 'absolute', right: '20px', bottom: '-10px', color:'#b7e0d6ff'}}/></p>
    </div>   
    <div className='teal-container'>
      <b><h2>Quality Features</h2></b>
      <p> 
        We will use a process called supervised learning to train our models using relevant information 
        from our dataset, called features, such as data analysis methods. In supervised learning, the model 
        is trained with human-labeled text from systematic reviews and then tested with labeled data that the 
        algorithm has not seen before to measure its accuracy. </p>
        <br></br> <p>            
        The unique items mapped from the AMSTAR 2 and ROBIS tools will be used as the “quality” features. 
         These manual tools have already been empirically validated, meaning they have undergone rigorous 
         testing of face and content validity, and have been pilot-tested for usability and reliability 
         (more details here). We used the results of a previous empirical study comparing the tools by a 
         member of our team which mapped an items concept across the two tools. This mapping process revealed 
         21 unique quality features which we implemented into the WISEST AI. 
         <IoDocumentTextSharp style={{position: 'absolute', right: '20px', bottom: '-10px', color: '#fee599ff'}}/>
      </p>      
    </div>

    <div className='yellow-container'>
      <b><h2>Metadata Features</h2></b>     
      <p>Characteristics of the systematic reviews collected as “metadata” will be shown to the user but will 
        not be included as quality indicators in our models.
      <BsFillPeopleFill style={{ position: 'absolute', right: '20px', bottom: '-15px', color: '#f0f4f7ff'}}/>
      </p>
    </div>

    <div className='grey-container'>
    <b><h2>Equity Features</h2></b> 
      <p>We determined if the systematic reviews considered setting, sex, gender, indigenous or other racial and 
        cultural perspectives in their analysis using the PROGRESS-PLUS tool. 
        <MdSummarize style={{ position: 'absolute', right: '20px', bottom: '-18px', color: '#c4cbebff'}}/>
        </p>
    </div>

    <div className='purple-container'>
      <b><h2>Summary Features</h2></b>
      <p> Summary ratings will be used to show the overall quality, or the overall risk of bias for the entire 
        systematic review. </p>
        <p> From the AMSTAR 2 checklist, the quality ratings can be high quality or low quality.
          For the ROBIS tool, the risk of bias judgment can be High risk or Low risk.</p>
        <p> If multiple systematic reviews were uploaded by the user for comparison purposes, we also ranked the 
          systematic reviews by type of synthesis, and we presented a summation of each systematic reviews conclusions.</p>
    </div>

    {/* Building the Dataset */}
    <div className='building-section'>
      <div className='heading'>BUILDING A DATASET OF 2000 SYSTEMATIC REVIEWS</div>
        <p> “According to a 2020 survey, most AI developers spend nearly 70% of their time preparing their datasets, hence we have devoted 
            a year to this task” </p>
          <picture>
            <source srcSet={RoadMapDesktop} alt="Roadmap Systematic Review" media='(min-width : 768px)'></source>
            <source srcSet={RoadMapMobile} alt="Roadmap Systematic Review" media = '(max-width : 768px)'></source>
            <img  className="building-chart" src={RoadMapDesktop}/>
          </picture>         
      <p>To develop our model, we aim to build a human labelled dataset of 2000 systematic reviews.</p>
      <p>To do this, we used Cochrane Engage to crowdsource volunteer collaborators with experience in systematic review methods and skills.        
      </p>
      <ul className= 'progress-list'>
      <li className='developing-list-items'>We began by sending training materials to collaborators as a first step to do remote self-training. 
        We sent them details decision rules about how to assess each item in AMSTAR 2 and ROBIS, and instructions on how to use our data assessment 
        form. </li>
      <li className='developing-list-items'>We then convened a meeting via Zoom to go over an example assessment and answer questions.</li>  
      
    </ul>
      <p>As collaborators began to assess systematic reviews, expert feedback was given on each item that was not assessed correctly until 
         100% quality was achieved. Our aim is to assess 2000 systematic reviews using both tools to be used to train our models within one
         year (2023-2024).</p>
    </div>
   
   {/* Developing the AI Model */}
   <div className='developing-section'>
    <div className='heading'>DEVELOPING THE AI MODEL</div>
    <img className="developing-chart-image" src={Developing} alt='Developing Wisest AI Chart'></img>
    <p> <b>For our machine learning methods:</b> </p>
    <ol className='developing-list' typeof='1'>
      <li className='developing-list-items'>For every 100 systematic reviews we acquire and extract, we will do a Gap Analysis to identify missing quotes all items, missing 
        systematic review types, and missing health conditions</li>
      <li className='developing-list-items'>We will clean the data so it is ready to be evaluated by the AI models</li>  
      <li className='developing-list-items'>Test the models to determine performance</li> 
      <li className='developing-list-items'>Add more data if models underperform for specific items</li> 
      <li className='developing-list-items'> Deploy a user interface for the AI </li>
    </ol>
   </div>


    {/* Progress to Date */}
   <div className='progress-section'>
   <div className='heading'>PROGRESS TO DATE</div>
   <div className='progress-chart-container'>   
      <div class="wrapper">
      <figcaption><b>Assements completed, checked, and in progres (N = 175)</b></figcaption>
      <img className='pie-chart' src={PieChart} alt='Pie Chart of Assements Completed'></img>
      </div>    
     <div class="wrapper">
      <figcaption><b>Geographic Locations of volunteer collaborators</b></figcaption> 
      <img  className='pie-chart' src={Map} alt='Map of Volunteers'></img> 
      </div>     
    </div>
    <ul className= 'progress-list'>
      <li className='developing-list-items'>56 volunteer collaborators recruited; 35 active</li>
      <li className='developing-list-items'>Of 186 assessments completed: 65 assessed and checked by a senior methodologist, 99 are pending 
      validity checking, and 22 in-progress</li>  
      <li className='developing-list-items'>Gap analysis identified no gaps in quotes or SR types, but we did find gaps in health conditions
      </li>       
    </ul>        
   </div> 
   </div>
   </>
  );
};

export default DevelopingWisest;
