import React, { useContext, useState } from 'react';
import './AdminDashboard.css';
import { MdEdit, MdCheck, MdClose } from 'react-icons/md';
import { BACKEND_URL } from '../../utils/constants';
import { AuthContext } from '../AuthenticationPages/AuthContext';

const CurrentEvent = ({ id, name, description, link, fetchData, setEvents }) => {
    
    const { token } = useContext(AuthContext);
    
    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(name);
    const [editedDescription, setEditedDescription] = useState(description);
    const [editedLink, setEditedLink] = useState(link);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedName(name);
        setEditedDescription(description);
        setEditedLink(link);
    };

    const handleSave = () => {
        const eventData = { title: editedName, description: editedDescription, link: editedLink };
        const formDataJsonString = JSON.stringify(eventData);

        fetch(`${BACKEND_URL}/events/${id}`, {
            method: "PUT",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: formDataJsonString,
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                alert(data.message);
                fetchData("events", setEvents);
            }
            setIsEditing(false);
        })
        .catch(error => {
            alert("Error updating event: " + error);
            setIsEditing(false);
        });
    };

    const deleteEvent = () => {
        if (window.confirm("Are you sure you want to delete this event?")) {
            fetch(`${BACKEND_URL}/events/${id}`, {
                method: "DELETE",
                headers: { 
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    alert(data.message);
                    fetchData("events", setEvents); // Refresh event list
                }
            })
            .catch(error => alert("Error deleting event: " + error));
        }
    };

    return (
        <div className="current-event">
            <div className="event-info">
                {isEditing ? (
                    <>
                        <input className="event-input" value={editedName} style={{ marginBottom: '5px' }} onChange={(e) => setEditedName(e.target.value)} />
                        <input className="event-input" value={editedDescription} style={{ marginBottom: '5px' }} onChange={(e) => setEditedDescription(e.target.value)} />
                        <input className="event-input" value={editedLink} onChange={(e) => setEditedLink(e.target.value)} />
                    </>
                ) : (
                    <>
                        <p className="event-name">{name}</p>
                        <p className="event-description">{description}</p>
                        <p className="event-link" style={{ color: 'var(--purple)' }}>{link}</p>
                    </>
                )}
            </div>
            <div className="event-buttons">
                {isEditing ? (
                    <>
                        <div className="event-button cancel-event-button" onClick={handleCancel}>
                            <MdClose color='red' size={'23px'} />
                        </div>
                        <div className="event-button save-event-button" onClick={handleSave}>
                            <MdCheck color='green' size={'23px'} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="event-button edit-event-button" onClick={handleEdit}>
                            <MdEdit color='white' size={'23px'} />
                        </div>
                        <div className="event-button delete-event-button" onClick={deleteEvent}>
                            X
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default CurrentEvent;
