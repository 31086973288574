import SearchBar from "./FilterElements/SearchBar";
import FilterMenu from "./FilterElements/FilterMenu";
import "./eventsFilter.css";

export default function EventsFilter({
    activeTypeFilters, typeFilterToggler, dateFilterSetter, currentDateFilter, searchFilterSetter
}) {
    return (
        <div className="events-filter-container">
            <SearchBar searchFilterSetter={searchFilterSetter}/>
        </div>
    );
}