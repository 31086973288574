/**
   Create a react component representing the logo
   in the navigation bar.
   When the screen becomes small enough, the logo
   will switch to the smaller version without text.

    @author Alessandro Marmi, Cassandra Tin Kwon Yuen
*/

import {React} from 'react';
import WebsiteLogoWide from "../../images/logo_wide2.png";
import WebsiteLogoLeft from "../../images/logo_wide3.png";

const Logo = () => {
  return (
    <picture>
      <source srcSet={WebsiteLogoWide} alt='LOGO' id='logo' media="(min-width:1000px)"></source>
      <source srcSet={WebsiteLogoLeft} alt='LOGO' id='logo' media="(max-width:1000px)"></source>
      <img src={WebsiteLogoWide} alt="LOGO" id="logo"/>
    </picture>
  );
};

export default Logo;