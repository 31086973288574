import EventCard from './ListElements/EventCard';
import { filterEventsByType, filterEventsByDate, filterEventsBySearch } from './eventsListFunctions';
import './eventsList.css';

export default function EventsList({ 
    eventList, showcaseSize, currentPage, searchFilter
}) {

    let filteredEvents = eventList;
    
    if (searchFilter) {
        filteredEvents = filterEventsBySearch(filteredEvents, searchFilter);
    }

    const startIndex = (currentPage - 1) * showcaseSize;
    const endIndex = startIndex + showcaseSize;
    const eventsToShowcase = filteredEvents.slice(startIndex, endIndex);

    const events = eventsToShowcase.map((event) => {
        return (
            <EventCard
                eventTitle={event.title}
                eventDescription={event.description}
                eventLink={event.link}
            />
        );
    });

    return (
        <div className="events-list">
            {events}
        </div>
    );
}