import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check for stored login state and user name on component mount
    const storedIsLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
    const storedUserName = sessionStorage.getItem('userName') || '';
    const storedUserId = sessionStorage.getItem('userId');
    const storedIsAdmin = sessionStorage.getItem('isAdmin') === 'true';
    const storedToken = sessionStorage.getItem('token');

    if (storedIsLoggedIn) {
      setIsLoggedIn(storedIsLoggedIn);
      setUserName(storedUserName);
      setUserId(storedUserId);
      setIsAdmin(storedIsAdmin);
      setToken(storedToken);
    }
    setLoading(false);
  }, []);

  function logoutUser() {
    // Clear session storage
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('isAdmin');
    sessionStorage.removeItem('token'); 
  
    // Reset state
    setIsLoggedIn(false);
    setUserName('');
    setUserId(null);
    setIsAdmin(false);
    setToken(null);
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, userName, setUserName, userId, setUserId, isAdmin, setIsAdmin, token,
      setToken, logoutUser }}>
      {!loading ? children : <div>Loading authentication data...</div>}
    </AuthContext.Provider>
  );
};
